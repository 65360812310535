/*============================
    CSS Index         
==============================

    01. Default Css
        - Breadvrumb   
        - Scroll top 
    02. Section Spacing Css
    03. Offcanvas Minicart Area
    04. container Css 
    05. Header Css 
        - Left Sidebar Menu 
        - Navigation Menu Css
        - Multilevel Submenu Css
        - Mega Menu Css
        - Mobile Menu Overlay Css
    06. Footer Css
    07. Sidebar Css 
    08. Hero Slider Css 
    09. About Us Css 
    10. Product Details Css 
    11. Shop Pages Css
    12. Cart & Checkout Pages Css
    13. Contact Us Pages Css
    14. preview Pages Css
    15. Button Css
    16. Featuted Product Css
    17. Product Css
    18. Product Tab list Css 
    19. Single Product details Css 
    20. Countdown Css
    21. Blog Css
    22. Newsletter Css 
    23. Banner Product Css 
    24. Brand Css


/*=====  End of CSS Index  ======*/
/*=============================================
=             01. Default Css                 =
=============================================*/
@import url("https://fonts.googleapis.com/css2?family=Libre+Baskerville:wght@400;700&family=Mr+De+Haviland&family=Prata&family=Roboto:wght@100;300;400;500;700;900&display=swap");
*, *::after, *::before {
  box-sizing: border-box;
}

html, body {
  height: 100%;
}

body {
  line-height: 1.74;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  visibility: visible;
  font-family: "Roboto", sans-serif;
  color: #000000;
  position: relative;
  background-color: #ffffff;
}
body.no-overflow {
  overflow: hidden;
}

.site-wrapper-reveal {
  background: #ffffff;
}

h1, h2, h3, h4, h5, h6 {
  color: #111;
  margin-top: 0;
  margin-bottom: 0;
  line-height: 1.41;
}

h1 {
  font-size: 60px;
  line-height: 1.1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h1 {
    font-size: 46px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h1 {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  h1 {
    font-size: 34px;
  }
}

h2 {
  font-size: 36px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h2 {
    font-size: 32px;
  }
}
@media only screen and (max-width: 767px) {
  h2 {
    font-size: 30px;
  }
}

h3 {
  font-size: 30px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h3 {
    font-size: 28px;
  }
}
@media only screen and (max-width: 767px) {
  h3 {
    font-size: 25px;
  }
}

h4 {
  font-size: 26px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  h4 {
    font-size: 24px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  h4 {
    font-size: 22px;
  }
}
@media only screen and (max-width: 767px) {
  h4 {
    font-size: 22px;
  }
}

h5 {
  font-size: 24px;
}
@media only screen and (max-width: 767px) {
  h5 {
    font-size: 20px;
  }
}

h6 {
  font-size: 18px;
}

p:last-child {
  margin-bottom: 0;
}

a, button {
  color: inherit;
  display: inline-block;
  line-height: inherit;
  text-decoration: none;
  cursor: pointer;
}

a, button, img, input, span {
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

*:focus {
  outline: none !important;
}

a:focus {
  color: inherit;
  outline: none;
  text-decoration: none;
}

.btn.focus, .btn:focus {
  outline: none;
  box-shadow: none;
}

a:hover {
  text-decoration: none;
  color: #dcb14a;
}

button, input[type=submit] {
  cursor: pointer;
}

ul {
  list-style: outside none none;
  margin: 0;
  padding: 0;
}

.h-lg {
  font-size: 48px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .h-lg {
    font-size: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .h-lg {
    font-size: 34px;
  }
}

label {
  margin-bottom: 5px;
}

.font-lg-p {
  font-size: 18px;
}

.mark, mark {
  padding: 0 0;
  background-color: transparent;
}

.font-weight--bold {
  font-weight: 700;
}

.font-weight--reguler {
  font-weight: 500;
}

.font-weight--normal {
  font-weight: 400;
}

.font-weight--light {
  font-weight: 300;
}

.site-wrapper-reveal {
  position: relative;
  z-index: 2;
  background: #ffffff;
}

/*--
    Default Class
*/
.z-index-1 {
  z-index: 1;
}

.text-color-primary {
  color: #dcb14a;
}

.text-color-secondary {
  color: #d2a98e;
}

.text-black {
  color: #111 !important;
}

.bg-white {
  background: #ffffff;
}

.bg-gray {
  background: #f4f5f7;
}

.bg-gray-2 {
  background: #F6FAFE;
}

.bg-gray-3 {
  background: #f6f2ed;
}

.bg-theme-default {
  background: #dcb14a;
}

.theme-bg-secondary {
  background: #d2a98e;
}

.black-bg {
  background: #000000;
}

.border-radus-5 {
  border-radius: 5px;
}

.sub-heading {
  color: #999;
}

.text-red {
  color: red;
}

.text-green {
  color: #d2a98e;
}

.bg-gradient {
  background: -webkit-linear-gradient(top, #FFF 0, #F5F5F5 100%);
}

/*-- Tab Content & Pane Fix --*/
select {
  padding: 3px 20px;
  height: 56px;
  max-width: 100%;
  width: 100%;
  outline: none;
  border: 1px solid #f8f8f8;
  border-radius: 5px;
  background: #f8f8f8 url("../images/icons/selector-icon.webp") no-repeat center right 20px;
  background-color: #f8f8f8;
  -moz-appearance: none;
  -webkit-appearance: none;
}
select:focus {
  background: #f8f8f8 url("../images/icons/selector-icon.webp") no-repeat center right 20px !important;
}

.fixed-bg {
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
}

/*------- Defauld Class --------*/
.text-black {
  color: #333;
}

.box-shadow-top {
  box-shadow: 0 10px 15px rgba(0, 0, 0, 0.05);
}

.border {
  border: 1px solid #ededed !important;
}

.border-top {
  border-top: 1px solid #ededed !important;
}

.border-right {
  border-right: 1px solid #ededed !important;
}

.border-bottom {
  border-bottom: 1px solid #ededed !important;
}

.border-left {
  border-left: 1px solid #ededed !important;
}

.border-top-dash {
  border-top: 1px dashed #ddd !important;
}

.border-bottom-dash {
  border-bottom: 1px dashed #ddd !important;
}

.border-top-thick {
  border-top: 2px solid #ededed !important;
}

.border-bottom-thick {
  border-bottom: 2px solid #ededed !important;
}

.border-top-drak {
  border-top: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.border-bottom-drak {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2) !important;
}

.border-top-black {
  border-top: 1px solid #333;
}

.border-bottom-black {
  border-bottom: 1px solid #333;
}

.bg-img {
  background: #888;
}

.sub-heading {
  font-size: 14px;
}

.section-title--one {
  position: relative;
  padding-bottom: 10px;
}
.section-title--one::after {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  background: #dcb14a;
  height: 4px;
  width: 70px;
}
.section-title--center::after {
  position: absolute;
  content: "";
  left: 50%;
  bottom: 0;
  background: #dcb14a;
  height: 4px;
  width: 70px;
  transform: translateX(-50%);
}

.section-sub-title {
  font-size: 14px;
  letter-spacing: 3px;
  text-transform: uppercase;
  line-height: 1.43;
  margin-top: -5px;
}

.section-under-heading {
  font-size: 18px;
  font-weight: 500;
  line-height: 1.4;
  color: #111;
}
.section-under-heading a {
  color: #dcb14a;
  border-bottom: 1px solid #ccc;
  position: relative;
}
.section-under-heading a::before {
  content: "";
  width: 0;
  height: 1px;
  bottom: -1px;
  position: absolute;
  left: auto;
  right: 0;
  z-index: 1;
  -webkit-transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}
.section-under-heading a:hover::before {
  width: 100%;
  left: 0;
  right: auto;
}

.img-width {
  width: 100%;
}

.title-dec-text {
  text-align: center;
  max-width: 760px;
  margin: auto;
}

::selection {
  color: #ffffff;
  background-color: #dcb14a;
}

form {
  /* FF 4-18 */
  /* FF 19+ */
  /* IE 10+ */
}
form input:focus::-webkit-input-placeholder {
  color: transparent;
}
form input:focus:-moz-placeholder {
  color: transparent;
}
form input:focus::-moz-placeholder {
  color: transparent;
}
form input:focus:-ms-input-placeholder {
  color: transparent;
}
form input::placeholder, form textarea::placeholder {
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}

input[type=text]:focus, input[type=email]:focus, input[type=url]:focus, input[type=password]:focus, input[type=search]:focus, input[type=number]:focus, input[type=tel]:focus, input[type=range]:focus, input[type=date]:focus, input[type=month]:focus, input[type=week]:focus, input[type=time]:focus, input[type=datetime]:focus, input[type=datetime-local]:focus, input[type=color]:focus, textarea:focus, select:focus, select:focus, textarea:focus {
  color: #dcb14a;
  border-color: #dcb14a;
}

input[type=checkbox] {
  position: relative;
  background: 0 0;
  border-width: 0;
  box-shadow: none;
  margin: 0 10px 0 3px;
  cursor: pointer;
}

.box-home {
  background-color: #f4f5f7;
}

.page-box {
  max-width: 1330px;
  margin: auto;
  background-color: #fff;
}
.page-box .header-sticky.is-sticky {
  max-width: 1330px;
  margin: auto;
  left: 0;
  right: 0;
}

/*===================================
    - Breadvrumb  
=================================*/
.breadcrumb-area {
  background-color: #f4f5f7;
  background-repeat: no-repeat;
  background-position: center center;
  border-bottom-width: 0px;
  padding-top: 80px;
  padding-bottom: 80px;
  background-size: cover;
}
@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .breadcrumb-area {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}

.breadcrumb-list li {
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 2px;
  line-height: 1.58;
  text-transform: uppercase;
}
.breadcrumb-list li a {
  position: relative;
}
.breadcrumb-list li a::after {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: currentColor;
}
.breadcrumb-list li a:hover::after {
  width: 100%;
  left: 0;
  right: auto;
  z-index: 0;
}
.breadcrumb-list li.active {
  color: #777;
}
@media only screen and (max-width: 575px) {
  .breadcrumb-list {
    margin-top: 20px;
  }
}

.page-pagination li {
  display: inline-block;
}
.page-pagination li a {
  font-weight: 500;
  padding: 0 10px;
  display: block;
  text-align: center;
  line-height: 41px;
  min-width: 41px;
  height: 41px;
  text-transform: uppercase;
  color: #ababab;
  letter-spacing: 2px;
  border-radius: 5px;
}
.page-pagination li a.current {
  background: #f6f5f9;
  color: #dcb14a;
}
.page-pagination li a:hover {
  color: #dcb14a;
}

/*=============================================
   - Scroll top         
=============================================*/
.scroll-top {
  position: fixed;
  right: 30px;
  bottom: -60px;
  z-index: 999;
  box-shadow: 0 30px 50px rgba(0, 0, 0, 0.03);
  display: block;
  padding: 0;
  width: 60px;
  height: 60px;
  border-radius: 50%;
  text-align: center;
  font-size: 25px;
  line-height: 60px;
  cursor: pointer;
  opacity: 0;
  visibility: hidden;
  background-color: #dcb14a;
  background-size: 200% auto;
  background-position: left center;
  color: #ffffff;
  transition: all 0.5s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow: hidden;
}
@media only screen and (max-width: 479px) {
  .scroll-top {
    width: 50px;
    height: 50px;
    line-height: 50px;
    font-size: 20px;
  }
}
.scroll-top.show {
  visibility: visible;
  opacity: 1;
  bottom: 60px;
}
.scroll-top i {
  position: absolute;
  top: 50%;
  left: 50%;
  color: #ffffff;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.scroll-top .arrow-top {
  transform: translate(-50%, -50%);
}
.scroll-top .arrow-bottom {
  transform: translate(-50%, 80px);
}
.scroll-top:hover {
  background-position: right center;
}
.scroll-top:hover .arrow-top {
  transform: translate(-50%, -80px);
}
.scroll-top:hover .arrow-bottom {
  transform: translate(-50%, -50%);
}

.error-page-content .icon {
  font-size: 63px;
  color: #dcb14a;
}
.error-page-content .description {
  font-size: 18px;
}
.error-page-content .description a {
  color: #dcb14a;
  font-weight: 500;
  border-bottom: 1px solid;
}
.error-page-content .pages-search {
  max-width: 400px;
  margin: auto;
  position: relative;
}
.error-page-content .pages-search input {
  border: 1px solid #d9d9d9;
  padding: 13px 65px 13px 15px;
  -webkit-border-radius: 0;
  border-radius: 0;
  background-color: transparent;
  width: 100%;
}
.error-page-content .pages-search .submit-btn {
  width: 50px;
  height: 50px;
  background: transparent;
  position: absolute;
  top: 50%;
  right: 0;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border: none;
  padding: 10px;
}

/*=====  End of Default CSS  ======*/
/*============================================
=           02. Section Spacing Css          =
=============================================*/
.section-space {
  /* Section Padding Css */
  /* Section Margin Css */
}
.section-space--ptb_120 {
  padding-top: 120px;
  padding-bottom: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_120 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--ptb_120 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.section-space--pt_120 {
  padding-top: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_120 {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pt_120 {
    padding-top: 60px;
  }
}
.section-space--pb_120 {
  padding-bottom: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_120 {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pb_120 {
    padding-bottom: 60px;
  }
}
.section-space--ptb_100 {
  padding-top: 100px;
  padding-bottom: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_100 {
    padding-top: 80px;
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--ptb_100 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.section-space--pt_100 {
  padding-top: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_100 {
    padding-top: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pt_100 {
    padding-top: 60px;
  }
}
.section-space--pb_100 {
  padding-bottom: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_100 {
    padding-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pb_100 {
    padding-bottom: 60px;
  }
}
.section-space--ptb_90 {
  padding-top: 90px;
  padding-bottom: 90px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_90 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--ptb_90 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.section-space--pt_90 {
  padding-top: 90px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_90 {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pt_90 {
    padding-top: 40px;
  }
}
.section-space--pb_90 {
  padding-bottom: 90px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_90 {
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pb_90 {
    padding-bottom: 40px;
  }
}
.section-space--ptb_80 {
  padding-top: 80px;
  padding-bottom: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_80 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--ptb_80 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
.section-space--pt_80 {
  padding-top: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_80 {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pt_80 {
    padding-top: 40px;
  }
}
.section-space--pb_80 {
  padding-bottom: 90px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_80 {
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pb_80 {
    padding-bottom: 40px;
  }
}
.section-space--ptb_70 {
  padding-top: 70px;
  padding-bottom: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_70 {
    padding-top: 40px;
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--ptb_70 {
    padding-top: 30px;
    padding-bottom: 30px;
  }
}
.section-space--pt_70 {
  padding-top: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_70 {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pt_70 {
    padding-top: 30px;
  }
}
.section-space--pb_70 {
  padding-bottom: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_70 {
    padding-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pb_70 {
    padding-bottom: 30px;
  }
}
.section-space--ptb_60 {
  padding-top: 60px;
  padding-bottom: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--ptb_60 {
    padding-top: 60px;
    padding-bottom: 60px;
  }
}
.section-space--pt_60 {
  padding-top: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pt_60 {
    padding-top: 60px;
  }
}
.section-space--pb_60 {
  padding-bottom: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pb_60 {
    padding-bottom: 60px;
  }
}
.section-space--pt_40 {
  padding-top: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pt_40 {
    padding-top: 30px;
  }
}
.section-space--pb_40 {
  padding-bottom: 40px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--pb_40 {
    padding-bottom: 30px;
  }
}
.section-space--ptb_30 {
  padding-top: 30px;
  padding-bottom: 30px;
}
.section-space--pt_30 {
  padding-top: 30px;
}
.section-space--pb_30 {
  padding-bottom: 30px;
}
.section-space--mt_15 {
  margin-top: 15px;
}
.section-space--mt_20 {
  margin-top: 20px;
}
.section-space--mt_30 {
  margin-top: 30px;
}
.section-space--mt_40 {
  margin-top: 40px;
}
.section-space--mt_50 {
  margin-top: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_50 {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--mt_50 {
    margin-top: 30px;
  }
}
.section-space--mt_60 {
  margin-top: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_60 {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--mt_60 {
    margin-top: 30px;
  }
}
.section-space--mt_70 {
  margin-top: 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_70 {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--mt_70 {
    margin-top: 30px;
  }
}
.section-space--mt_80 {
  margin-top: 80px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_80 {
    margin-top: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--mt_80 {
    margin-top: 30px;
  }
}
.section-space--mt_100 {
  margin-top: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_100 {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--mt_100 {
    margin-top: 60px;
  }
}
.section-space--mt_120 {
  margin-top: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mt_120 {
    margin-top: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--mt_120 {
    margin-top: 60px;
  }
}
.section-space--mb_15 {
  margin-bottom: 15px;
}
.section-space--mb_20 {
  margin-bottom: 20px;
}
.section-space--mb_30 {
  margin-bottom: 30px;
}
.section-space--mb_40 {
  margin-bottom: 40px;
}
@media only screen and (max-width: 767px) {
  .section-space--mb_40 {
    margin-bottom: 30px;
  }
}
.section-space--mb_50 {
  margin-bottom: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_50 {
    margin-bottom: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--mb_50 {
    margin-bottom: 30px;
  }
}
.section-space--mb_60 {
  margin-bottom: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_60 {
    margin-bottom: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--mb_60 {
    margin-bottom: 30px;
  }
}
.section-space--mb_100 {
  margin-bottom: 100px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_100 {
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--mb_100 {
    margin-bottom: 60px;
  }
}
.section-space--mb_120 {
  margin-bottom: 120px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .section-space--mb_120 {
    margin-bottom: 80px;
  }
}
@media only screen and (max-width: 767px) {
  .section-space--mb_120 {
    margin-bottom: 60px;
  }
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-15 {
  margin-bottom: 15px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-25 {
  margin-bottom: 25px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mt-10 {
  margin-top: 10px;
}

.mt-15 {
  margin-top: 15px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-25 {
  margin-top: 25px;
}

.mt-30 {
  margin-top: 30px;
}

.mt-40 {
  margin-top: 40px;
}

@media only screen and (max-width: 767px) {
  .small-mt__0 {
    margin-top: 0px;
  }

  .small-mt__10 {
    margin-top: 10px;
  }

  .small-mt__20 {
    margin-top: 20px;
  }

  .small-mt__30 {
    margin-top: 30px;
  }

  .small-mt__40 {
    margin-top: 40px;
  }

  .small-mt__50 {
    margin-top: 50px;
  }

  .small-mt__60 {
    margin-top: 60px;
  }

  .small-mb__30 {
    margin-bottom: 30px;
  }

  .small-mb__40 {
    margin-bottom: 40px;
  }

  .small-mb__50 {
    margin-bottom: 50px;
  }

  .small-mb__60 {
    margin-bottom: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .tablet-mt__0 {
    margin-top: 0px;
  }

  .tablet-mt__30 {
    margin-top: 30px;
  }

  .tablet-mt__40 {
    margin-top: 40px;
  }

  .tablet-mt__50 {
    margin-top: 50px;
  }

  .tablet-mt__60 {
    margin-top: 60px;
  }

  .tablet-mb__30 {
    margin-bottom: 30px;
  }

  .tablet-mb__40 {
    margin-bottom: 40px;
  }

  .tablet-mb__50 {
    margin-bottom: 50px;
  }

  .tablet-mb__60 {
    margin-bottom: 60px;
  }
}
/*=====  End of spacing  ======*/
/*======================================
=        03. Offcanvas Minicart Area   =
=======================================*/
.offcanvas-minicart_wrapper .offcanvas-menu-inner {
  padding: 60px;
  width: 450px;
  box-shadow: 0px 0 15px rgba(0, 0, 0, 0.2);
  background-color: #ffffff;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .offcanvas-minicart_wrapper .offcanvas-menu-inner {
    width: 100%;
    padding: 53px 50px 45px;
  }
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .close-btn-box {
  text-align: left;
  margin-bottom: 30px;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .btn-close {
  background: transparent;
  color: #333;
  top: 0;
  right: 0;
  left: auto;
  text-align: right;
  font-size: 20px;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .btn-close:hover {
  color: #212121;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list {
  max-height: 311px;
  position: relative;
  overflow: auto;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li {
  padding-bottom: 30px;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li:last-child {
  padding-bottom: 0;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li:last-child.minicart-product {
  border-bottom: none;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product {
  display: flex;
  border-bottom: 1px solid #ddd;
  margin-bottom: 20px;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product > a.product-item_remove > i {
  position: absolute;
  font-size: 14px;
  top: auto;
  right: 15px;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content {
  flex-basis: calc(100% - 70px);
  max-width: calc(100% - 70px);
  padding-left: 20px;
  padding-right: 10px;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content a.product-item_title {
  color: #000000;
  line-height: 1.4;
  font-size: 15px;
  font-weight: 500;
  margin-bottom: 5px;
  width: 90%;
  display: block;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content a.product-item_title:hover {
  color: #333;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content label {
  font-weight: 500;
  display: block;
  margin-bottom: 0;
  margin-top: 0;
  font-size: 15px;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content label span {
  color: #666;
  padding-left: 10px;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-content .minicart-list > li.minicart-product .product-item_content .product-item_quantity {
  display: block;
  font-size: 13px;
  color: #333;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-item_total {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 35px 0;
  font-size: 24px;
  font-weight: 500;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area {
  padding-bottom: 15px;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .minicart-btn_area .hiraola-btn {
  height: 60px;
  line-height: 60px;
  color: #ffffff !important;
}

.global-overlay.overlay-open {
  position: fixed;
  height: 100%;
  width: 100%;
  top: 0;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  background: rgba(0, 0, 0, 0.7);
  z-index: 999;
}

/* ---Offcanvas--- */
.offcanvas-minicart_wrapper .offcanvas-menu-inner {
  background-color: #fff;
  position: fixed;
  top: 0;
  right: -440px;
  width: 440px;
  height: 100%;
  z-index: 9999;
  visibility: hidden;
  overflow-y: auto;
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.offcanvas-minicart_wrapper .offcanvas-menu-inner .offcanvas-inner_logo {
  padding: 30px 0;
  text-align: center;
}
@media only screen and (max-width: 767px) {
  .offcanvas-minicart_wrapper .offcanvas-menu-inner {
    width: 300px;
    padding: 60px 20px 0 !important;
  }
}
.offcanvas-minicart_wrapper.open .offcanvas-menu-inner {
  visibility: visible;
  left: auto;
  right: 0;
  opacity: 1;
  z-index: 9999;
}

/*=============================================
=            04. container Css            =
=============================================*/
.container-fluid--cp-150 {
  padding: 0 150px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .container-fluid--cp-150 {
    padding: 0 100px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container-fluid--cp-150 {
    padding: 0 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-fluid--cp-150 {
    padding: 0 50px;
  }
}
@media only screen and (max-width: 767px) {
  .container-fluid--cp-150 {
    padding: 0 30px;
  }
}
@media (min-width: 1700px) {
  .container-fluid--cp-140 {
    padding: 0 140px !important;
  }
}
@media (min-width: 1700px) and (max-width: 1663px) {
  .container-fluid--cp-140 {
    padding: 0 100px !important;
  }
}
@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-140 {
    padding: 0 30px !important;
  }
}
@media (min-width: 1200px) {
  .container-fluid--cp-140 {
    padding: 0 30px;
  }
}
@media (min-width: 1600px) {
  .container-fluid--cp-120 {
    padding: 0 120px !important;
  }
}
@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-120 {
    padding: 0 110px !important;
  }
}
@media (min-width: 1200px) {
  .container-fluid--cp-120 {
    padding: 0 50px;
  }
}
.container-fluid--cp-100 {
  padding: 0 100px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .container-fluid--cp-100 {
    padding: 0 50px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .container-fluid--cp-100 {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-fluid--cp-100 {
    padding: 0 30px;
  }
}
@media only screen and (max-width: 767px) {
  .container-fluid--cp-100 {
    padding: 0 15px;
  }
}
@media (min-width: 1600px) {
  .container-fluid--cp-80 {
    padding: 0 80px !important;
  }
}
@media (min-width: 1600px) and (max-width: 1664px) {
  .container-fluid--cp-80 {
    padding: 0 30px !important;
  }
}
@media (min-width: 1200px) {
  .container-fluid--cp-80 {
    padding: 0 30px;
  }
}
.container-fluid--cp-60 {
  padding: 0 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .container-fluid--cp-60 {
    padding: 0 15px;
  }
}
@media only screen and (max-width: 767px) {
  .container-fluid--cp-60 {
    padding: 0 15px;
  }
}
.container-fluid--cp-30 {
  padding: 0 30px;
}
@media only screen and (max-width: 767px) {
  .container-fluid--cp-30 {
    padding: 0 15px;
  }
}

@media (min-width: 1200px) {
  .page-content-double-sidebar .container {
    max-width: 1600px;
  }
}

@media (min-width: 1200px) {
  .container {
    max-width: 1170px;
  }
}
.container {
  max-width: 1170px;
}

.container_fl {
  padding-left: 15px;
  padding-right: 15px;
}

.col-06__left {
  width: 600px;
  max-width: 100%;
  float: right;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-06__left {
    float: none;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 40px;
  }
}

.col-06__right {
  width: 600px;
  max-width: 100%;
  float: left;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .col-06__right {
    float: none;
    margin-left: auto;
    margin-right: auto;
  }
}

.banner-image__content {
  width: 570px;
  max-width: 100%;
}

.row--35 {
  margin-left: -35px !important;
  margin-right: -35px !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row--35 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}
.row--35 > [class*=col], .row--35 > [class*=col-] {
  padding-left: 35px !important;
  padding-right: 35px !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--35 > [class*=col], .row--35 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--35 > [class*=col], .row--35 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .row--35 > [class*=col], .row--35 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--30 {
  margin-left: -30px;
  margin-right: -30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row--30 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}
.row--30 > [class*=col], .row--30 > [class*=col-] {
  padding-left: 30px;
  padding-right: 30px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--30 > [class*=col], .row--30 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--30 > [class*=col], .row--30 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .row--30 > [class*=col], .row--30 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--25 {
  margin-left: -25px;
  margin-right: -24px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--25 {
    margin-left: -15px;
    margin-right: -15px;
  }
}
@media only screen and (max-width: 767px) {
  .row--25 {
    margin-left: -15px !important;
    margin-right: -15px !important;
  }
}
.row--25 > [class*=col], .row--25 > [class*=col-] {
  padding-left: 25px;
  padding-right: 25px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .row--25 > [class*=col], .row--25 > [class*=col-] {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .row--25 > [class*=col], .row--25 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}
@media only screen and (max-width: 767px) {
  .row--25 > [class*=col], .row--25 > [class*=col-] {
    padding-left: 15px !important;
    padding-right: 15px !important;
  }
}

.row--6 {
  margin-left: -6px;
  margin-right: -6px;
}
.row--6 > [class*=col], .row--6 > [class*=col-] {
  padding-left: 6px;
  padding-right: 6px;
}

.row--5 {
  margin-left: -5px;
  margin-right: -5px;
}
.row--5 > [class*=col], .row--5 > [class*=col-] {
  padding-left: 5px;
  padding-right: 5px;
}

/*=====  End of container  ======*/
/*=============================================
=                05. Header Css               =
=============================================*/
.header-sticky.is-sticky {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  animation: 0.95s ease-in-out 0s normal none 1 running fadeInDown;
  z-index: 999;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  box-shadow: 0 1px 1px 0 #f0f0f0;
  background-color: #fff;
}
.header-sticky.is-sticky.header_height-120 {
  height: 90px;
}

.header_absolute {
  position: absolute;
  z-index: 9;
  width: 100%;
}

.header_height-90 {
  height: 90px;
  display: flex;
  align-items: center;
  width: 100%;
}

.header_height-120 {
  height: 120px;
  display: flex;
  align-items: center;
  width: 100%;
}

.top-logo-area {
  border-bottom: 1px solid #ddd;
  margin-top: 50px;
  padding-bottom: 50px;
}

.header-sticky .logo__hidden {
  display: none;
}
.header-sticky .content__hidden {
  display: inherit !important;
}
@media only screen and (max-width: 767px) {
  .header-sticky .logo__hidden {
    display: inherit;
  }
  .header-sticky .content__hidden {
    display: none !important;
  }
}

.is-sticky .top-logo-area {
  display: none;
}
.is-sticky .content__hidden {
  display: none !important;
}
.is-sticky .logo__hidden {
  display: inherit;
}

.header {
  display: flex;
  align-items: center;
  /* logo */
}

.header-left-search {
  width: 100%;
  max-width: 275px;
}
.header-left-search .header-search-box {
  position: relative;
}
.header-left-search .search-field {
  width: 100%;
  padding: 10px 35px 10px 0;
  border-width: 0 0 1px;
  border-color: rgba(0, 0, 0, 0.25);
  background-color: transparent;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.header-left-search .search-icon {
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  z-index: 9;
  font-size: 22px;
  color: #000;
  line-height: 1;
  cursor: pointer;
  z-index: 1;
  border: none;
  background: transparent;
}

.header-right-side {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.header-right-side .header-right-items {
  margin-left: 40px;
}
.header-right-side .header-right-items a {
  font-size: 24px;
}
.header-right-side .header-right-items a.mobile-navigation-icon {
  font-size: 30px;
}
.header-right-side .header-right-items a.mobile-navigation-icon i {
  margin-bottom: 5px;
}
@media only screen and (max-width: 575px) {
  .header-right-side .header-right-items {
    margin-left: 30px;
  }
}

.header-left-side {
  display: flex;
  justify-content: flex-start;
}
.header-left-side .header-right-items {
  margin-left: 0px;
  margin-right: 40px;
}
.header-left-side .header-right-items a {
  font-size: 24px;
}
.header-left-side .header-right-items a.mobile-navigation-icon {
  font-size: 30px;
}
.header-left-side .header-right-items a.mobile-navigation-icon i {
  margin-bottom: 5px;
}
@media only screen and (max-width: 575px) {
  .header-left-side .header-right-items {
    margin-right: 30px;
  }
}

.header-cart {
  position: relative;
}
.header-cart .item-counter {
  font-size: 12px;
  vertical-align: 0.3em;
  position: absolute;
  min-width: 19px;
  height: 19px;
  background-color: #dcb14a;
  text-align: center;
  line-height: 19px;
  -webkit-border-radius: 19px;
  border-radius: 19px;
  bottom: 1px;
  right: -10px;
  padding: 0 5px;
}
.header-cart:hover .item-counter {
  color: #ffffff;
}

.header-login-register-wrapper .modal-dialog {
  max-width: 450px;
  border-radius: 0;
}
.header-login-register-wrapper .modal-content {
  border: none;
  border-radius: 0rem;
}

.search-fields {
  position: relative;
}

.modal-box-wrapper, .myaccount-box-wrapper {
  background: #fff;
  width: 100%;
}
.modal-box-wrapper .helendo-tabs ul .tab__item, .myaccount-box-wrapper .helendo-tabs ul .tab__item {
  width: 50%;
  text-align: center;
}
.modal-box-wrapper .helendo-tabs ul .tab__item a, .myaccount-box-wrapper .helendo-tabs ul .tab__item a {
  color: #666;
  font-size: 18px;
  font-weight: 500;
  padding: 15px 0;
  background-color: #ededed;
}
.modal-box-wrapper .helendo-tabs ul .tab__item a.active, .myaccount-box-wrapper .helendo-tabs ul .tab__item a.active {
  background: #fff;
  color: #000000;
}
.modal-box-wrapper .content-modal-box, .myaccount-box-wrapper .content-modal-box {
  padding: 40px;
}
.modal-box-wrapper .content-modal-box .account-form-box .single-input, .myaccount-box-wrapper .content-modal-box .account-form-box .single-input {
  margin-top: 30px;
}
.modal-box-wrapper .content-modal-box .account-form-box .single-input input, .myaccount-box-wrapper .content-modal-box .account-form-box .single-input input {
  padding: 10px 20px;
  text-align: left;
  width: 100%;
  color: #666;
  border: 1px solid #ccc;
}
.modal-box-wrapper .content-modal-box .checkbox-wrap, .myaccount-box-wrapper .content-modal-box .checkbox-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.modal-box-wrapper .content-modal-box .checkbox-wrap label, .myaccount-box-wrapper .content-modal-box .checkbox-wrap label {
  margin-bottom: 0rem;
}
@media only screen and (max-width: 479px) {
  .modal-box-wrapper .content-modal-box .checkbox-wrap, .myaccount-box-wrapper .content-modal-box .checkbox-wrap {
    flex-direction: column;
    justify-content: flex-start;
  }
}
.modal-box-wrapper .content-modal-box .checkbox-wrap a, .myaccount-box-wrapper .content-modal-box .checkbox-wrap a {
  font-size: 14px;
}
.modal-box-wrapper .content-modal-box .input-checkbox, .myaccount-box-wrapper .content-modal-box .input-checkbox {
  opacity: 0;
  line-height: 1;
  height: inherit;
  display: none;
}
.modal-box-wrapper .content-modal-box span, .myaccount-box-wrapper .content-modal-box span {
  display: inline-block;
  text-transform: inherit;
  position: relative;
  line-height: 1;
  font-size: 14px;
  cursor: pointer;
  padding: 0 0 0 30px;
  font-weight: 400;
}
.modal-box-wrapper .content-modal-box span::before, .myaccount-box-wrapper .content-modal-box span::before {
  font-family: linearicons;
  content: "\e98d";
  font-size: 16px;
  position: absolute;
  top: -2px;
  left: 0px;
  transition: all 0.5s ease 0s;
}
.modal-box-wrapper .content-modal-box .input-checkbox:checked + span::before, .myaccount-box-wrapper .content-modal-box .input-checkbox:checked + span::before {
  content: "\e999";
}

.myaccount-box-wrapper .helendo-tabs ul {
  justify-content: center;
}
.myaccount-box-wrapper .helendo-tabs ul .tab__item {
  width: auto;
  text-align: center;
  font-size: 24px;
  padding: 0 22px;
}
.myaccount-box-wrapper .helendo-tabs ul .tab__item a {
  color: #666;
  font-size: 24px;
  font-weight: 600;
  padding: 15px 0;
  background-color: transparent;
}
.myaccount-box-wrapper .helendo-tabs ul .tab__item a.active {
  background: transparent;
  color: #000000;
}

.header-left-sidebar__content-header {
  height: 50px;
  position: absolute;
  left: 0;
  right: 0;
  background-color: #fff;
  z-index: 999;
}

.header-left-sidebar__content-footer {
  bottom: 0;
}

.header-left-sidebar__content-header {
  top: 0;
}

.header-left-sidebar {
  font-size: 14px;
  width: 300px;
  background-color: #fff;
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  z-index: 999;
  border-right: 1px solid #efefef;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-left-sidebar {
    width: 240px;
  }
}
@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .header-left-sidebar {
    display: none;
  }
}

.header-left-sidebar__content {
  height: 100%;
  overflow-y: auto;
  padding: 50px 40px 0;
  position: relative;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .header-left-sidebar__content {
    padding: 50px 20px 0;
  }
}

.page-template-template-home-left-sidebar {
  margin-left: 300px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .page-template-template-home-left-sidebar {
    margin-left: 240px;
  }
}
@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .page-template-template-home-left-sidebar {
    margin-left: 0;
  }
}

.newsletter-slidebar-box {
  position: relative;
}
.newsletter-slidebar-box input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ddd;
  background: transparent;
  padding: 0 40px 0 0;
}
.newsletter-slidebar-box .submit-button {
  background: transparent;
  position: absolute;
  right: 0px;
  border: none;
}

.helendo-language-currency .widget-language h6,
.helendo-language-currency .widget-currency h6 {
  font-size: 16px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 12px;
  line-height: 1;
}
.helendo-language-currency .widget-language ul li,
.helendo-language-currency .widget-currency ul li {
  margin-top: 8px;
}
.helendo-language-currency .widget-language ul li.actived a,
.helendo-language-currency .widget-currency ul li.actived a {
  color: #dcb14a;
}
.helendo-language-currency .widget-language ul li a,
.helendo-language-currency .widget-currency ul li a {
  color: #999;
  opacity: 1;
  height: auto;
  padding: 0;
}
.helendo-language-currency .widget-language ul li a:hover,
.helendo-language-currency .widget-currency ul li a:hover {
  color: #dcb14a;
}

.mobile-menu-contact-info h6, .mobile-menu-social-share h6 {
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
}
.mobile-menu-contact-info p, .mobile-menu-social-share p {
  color: #666;
}
.mobile-menu-contact-info .social-share li, .mobile-menu-social-share .social-share li {
  display: inline-block;
  margin-right: 15px;
}

.header__navigation {
  position: relative;
}

/*===============================
    - search overlay
===============================*/
.search-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9999;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  transform: translateY(100%);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.search-overlay__inner {
  width: 100%;
  height: 100%;
  cursor: default;
  background: #f4f5f7;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow-y: hidden;
}
.search-overlay.active {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
.search-overlay__header {
  background-color: #f4f5f7;
  padding: 50px 0;
}
.search-overlay__header .mobile-navigation-close-icon {
  font-size: 20px;
  cursor: pointer;
}
.search-overlay__body {
  width: 100%;
  margin: 0 auto;
  margin-bottom: 75px;
}
.search-overlay__form {
  position: relative;
  max-width: 1200px;
  padding: 0 15px;
  width: 100%;
  margin: auto;
}
.search-overlay__form input {
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.25);
  border-radius: 0;
  padding: 15px 50px 15px 0;
  width: 100%;
  color: #ffffff;
  font-size: 16px;
  height: 40px;
}
@media only screen and (max-width: 767px) {
  .search-overlay__form input {
    font-size: 30px;
    height: 60px;
  }
}
.search-overlay__form input::placeholder {
  color: #000000;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.search-overlay__form input[type=text]:focus {
  color: #000000;
  border-color: #000000;
}
.search-overlay__form .submit-button {
  position: absolute;
  right: 15px;
  border: none;
  font-size: 20px;
  background: transparent;
  cursor: pointer;
}
.search-overlay__form .submit-button :hover {
  color: #dcb14a;
}

.product-cats label {
  font-weight: 400;
  padding: 0 18px;
  cursor: pointer;
  font-size: 16px;
  color: #000;
}
.product-cats label input[type=checkbox], .product-cats label input[type=radio] {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 0;
}
.product-cats label input {
  display: none;
}
.product-cats label input:checked + span {
  color: #999;
}

/*============================== 
    - Navigation Menu Css
===============================*/
.navigation-menu > ul {
  display: flex;
}
.navigation-menu > ul > li {
  margin: 0 24px;
}
@media only screen and (min-width: 1500px) and (max-width: 1599px) {
  .navigation-menu > ul > li {
    margin: 0 22px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .navigation-menu > ul > li {
    margin: 0 15px;
  }
}
.navigation-menu > ul > li > a {
  display: block;
  color: #000000;
  padding: 36px 2px;
  position: relative;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.18;
}
.navigation-menu > ul > li.has-children > a {
  position: relative;
}
.navigation-menu > ul > li.has-children > a::before {
  content: "";
  position: absolute;
  width: 12px;
  height: 12px;
  border: 1px solid transparent;
  border-bottom-color: #d7d7d7;
  border-left-color: #d7d7d7;
  left: 50%;
  background-color: #fff;
  z-index: 9999;
  transform: rotate(135deg) translateX(50%) translateY(0px);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  bottom: -12px;
  opacity: 0;
}
.navigation-menu > ul > li.has-children--multilevel-submenu {
  position: relative;
}
.navigation-menu > ul > li.has-children:hover .megamenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
.navigation-menu > ul > li.has-children:hover .megamenu--home-variation__item {
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.navigation-menu > ul > li.has-children:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
}
.navigation-menu > ul > li:hover > a::before, .navigation-menu > ul > li.active > a::before {
  opacity: 1;
  bottom: -2px;
  transform: rotate(135deg) translateX(50%) translateY(0px);
}

/*===================================
    - Multilevel Submenu Css
====================================*/
.submenu {
  border: 1px solid #ddd;
  position: absolute;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  transform: translateY(10px);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  transition-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  min-width: 240px;
  padding: 20px 0;
  z-index: 9;
}
.submenu > li {
  position: relative;
}
.submenu > li > a {
  display: block;
  padding: 5px 30px;
  color: #ababab;
  font-weight: 400;
  transition: 0s;
}
.submenu > li > a > span {
  position: relative;
}
.submenu > li > a:hover {
  color: #000000;
}
.submenu > li:hover > .submenu {
  transform: translateY(0);
  visibility: visible;
  opacity: 1;
  z-index: 9;
}
.submenu > li.active > a {
  color: #dcb14a;
}
.submenu > li.has-children > a {
  position: relative;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.submenu > li.has-children.active > a {
  color: #dcb14a;
}
.submenu .submenu {
  top: 0px;
  left: 100%;
  right: auto;
}
.submenu .submenu.left {
  right: 100%;
  left: auto;
}
.submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}
.submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}
.submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}
.submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}
.submenu .submenu .submenu .submenu .submenu {
  top: 0;
  left: 100%;
  right: auto;
}
.submenu .submenu .submenu .submenu .submenu.left {
  right: 100%;
  left: auto;
}

/*=========================================
    - Mega Menu Css
===========================================*/
.megamenu {
  position: absolute;
  top: 100%;
  right: 0;
  left: -30%;
  background: url(../images/bg/bg-menu.webp);
  background-position: right top;
  background-repeat: no-repeat;
  background-attachment: scroll;
  background-size: cover;
  transform: translateY(10px);
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  transition-delay: 0.2s;
  transition-duration: 0.4s;
  visibility: hidden;
  opacity: 0;
  z-index: 9;
  border: 1px solid #ddd;
}
.megamenu--mega {
  min-width: 1080px;
  width: 100%;
  padding: 35px 20px 30px;
  display: flex;
  justify-content: space-around;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .megamenu--mega {
    min-width: 900px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .megamenu--mega {
    min-width: 900px;
    left: -40%;
  }
}
.megamenu--mega > li {
  flex-basis: 22%;
  padding-left: 15px;
  padding-right: 15px;
}
.megamenu--mega > li .page-list-title {
  font-size: 14px;
  margin-bottom: 20px;
  color: #111;
}
.megamenu--mega > li > ul > li > a {
  padding: 10px 0;
  color: #ababab;
  line-height: 1.2;
  transition: 0.1s;
}
.megamenu--mega > li > ul > li > a:hover {
  color: #000000;
}
.megamenu--mega > li > ul > li > a > span {
  position: relative;
}
.megamenu--mega > li > ul > li > a > span:after {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  background-color: #dcb14a;
  transition: 0.3s;
}
.megamenu--mega > li > ul > li.active > a {
  color: #dcb14a;
}

/*================================ 
    - Mobile Menu Overlay Css
==================================*/
.mobile-menu-overlay {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: #111;
  overflow: auto;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  z-index: 9999;
  background: rgba(0, 0, 0, 0.7);
  visibility: hidden;
  opacity: 0;
  overflow-x: hidden;
}
.mobile-menu-overlay__inner {
  transform: translateX(120%);
  width: 420px;
  height: 100%;
  float: right;
  cursor: default;
  background: #ffffff;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  overflow-y: auto;
}
@media only screen and (max-width: 479px) {
  .mobile-menu-overlay__inner {
    width: 300px;
  }
}
.mobile-menu-overlay__inner .mobile-menu-close-box {
  padding: 50px 40px 30px;
}
.mobile-menu-overlay__inner .mobile-menu-close-box .mobile-navigation-close-icon {
  font-size: 20px;
  cursor: pointer;
}
.mobile-menu-overlay.active {
  visibility: visible;
  opacity: 1;
}
.mobile-menu-overlay.active .mobile-menu-overlay__inner {
  transform: translateX(0%);
}
.mobile-menu-overlay__header {
  background-color: #ffffff;
  padding: 15px 0;
}
.mobile-menu-overlay__body {
  padding: 20px 40px 100px;
}
.mobile-menu-overlay__body .offcanvas-navigation {
  /* onepage style */
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a {
  display: block;
  color: #000000;
  padding-top: 18px;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.5;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li > a:hover {
  color: #666;
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li:last-child {
  border-bottom: 0;
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children {
  position: relative;
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children.active .menu-expand:before {
  content: "C";
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand {
  position: absolute;
  right: 0;
  top: 12px;
  cursor: pointer;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .menu-expand:before {
  content: "C";
  font-size: 18px;
  font-family: "ElegantIcons";
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu {
  padding: 12px 0 0px 10px;
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a {
  display: block;
  font-size: 15px;
  color: #000000;
  font-weight: 500;
  line-height: 1.5;
  padding: 10px 0;
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li a:hover {
  color: #666;
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li:last-child {
  border-bottom: 0;
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children {
  position: relative;
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children.active .menu-expand:before {
  content: "C";
  font-family: "ElegantIcons";
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand {
  position: absolute;
  right: 0;
  top: 6px;
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu li.has-children .menu-expand:before {
  content: "C";
  font-family: "ElegantIcons";
  font-size: 16px;
  font-weight: 500;
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children {
  position: relative;
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children.active .menu-expand:before {
  content: "C";
  font-family: "ElegantIcons";
}
.mobile-menu-overlay__body .offcanvas-navigation > ul > li.has-children .sub-menu .sub-menu li.has-children .menu-expand:before {
  content: "C";
  font-family: "ElegantIcons";
  font-size: 16px;
  font-weight: 500;
}
.mobile-menu-overlay__body .offcanvas-navigation--onepage > ul > li.active > a {
  font-weight: 700;
}

.preview-nav > ul > li {
  margin: 0 24px;
}
@media only screen and (min-width: 1500px) and (max-width: 1599px) {
  .preview-nav > ul > li {
    margin: 0 22px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .preview-nav > ul > li {
    margin: 0 15px;
  }
}
.preview-nav > ul > li > a {
  padding: 0px 0px;
  position: relative;
  font-size: 18px;
  font-weight: 600;
}
.preview-nav > ul > li > a::after {
  content: "";
  width: 0;
  height: 2px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  background-color: #000000;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.preview-nav > ul > li:hover > a::after, .preview-nav > ul > li.active > a::after {
  width: 100%;
  left: 0;
  right: auto;
}

/*=====  End of Header  ======*/
/*=========================================
=               06. Footer Css            =
===========================================*/
.reveal-footer {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .reveal-footer {
    position: static;
  }
}

.footer-widget-wrapper {
  margin-bottom: -35px;
}

.footer-widget {
  margin-bottom: 30px;
}
.footer-widget__title {
  margin-top: -3px;
  color: #111;
}
.footer-widget__list li {
  margin-bottom: 4px;
}
.footer-widget__list li i {
  margin-right: 10px;
}
.footer-widget__list li:last-child {
  margin-bottom: 0;
}
.footer-widget__list li .image_btn {
  border-radius: 5px;
  box-shadow: 0 0 20px rgba(51, 51, 51, 0.1);
  overflow: hidden;
}
.footer-widget__newsletter {
  position: relative;
}
.footer-widget__newsletter input {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ddd;
  background: transparent;
  padding: 0 40px 0 0;
}
.footer-widget__newsletter .submit-button {
  background: transparent;
  position: absolute;
  right: 5px;
  border: none;
}
@media only screen and (max-width: 767px) {
  .footer-widget__footer-menu {
    margin-bottom: 20px;
  }
}
.footer-widget__footer-menu li {
  display: inline-block;
  margin-right: 27px;
}
.footer-widget__footer-menu li:last-child {
  margin-right: 0;
}

.footer-social-networks .item {
  display: inline-block;
  margin-right: 20px;
}
.footer-social-networks .item:last-child {
  margin-right: 0;
}

.footer-bottom-social {
  display: flex;
  justify-content: flex-end;
}
@media only screen and (max-width: 767px) {
  .footer-bottom-social {
    justify-content: center;
    margin: 20px 0 0 0;
  }
}
.footer-bottom-social .title {
  margin-right: 60px;
  font-size: 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .footer-bottom-social .title {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .footer-bottom-social .title {
    margin-right: 20px;
  }
}

.instagram_gallery a {
  width: 80px;
  margin: 5px;
}
.instagram_gallery a img {
  width: 80px;
}

.copyright-text {
  display: block;
}

/*=====  End of footer ======*/
/*=======================================
=            07. Sidebar Css           =
=========================================*/
.shop-widget {
  border-bottom: 1px solid #ddd;
  margin-bottom: 40px;
  padding-bottom: 40px;
}
.shop-widget:last-child {
  border-bottom: none;
  margin-bottom: 0;
  padding-bottom: 0;
}

.widget-shop-categories .widget-nav-list li, .widget-size .widget-nav-list li, .widget-price .widget-nav-list li {
  margin-bottom: 6px;
}
.widget-shop-categories .widget-nav-list li:last-child, .widget-size .widget-nav-list li:last-child, .widget-price .widget-nav-list li:last-child {
  margin-bottom: 0;
}
.widget-shop-categories .widget-nav-list li a, .widget-size .widget-nav-list li a, .widget-price .widget-nav-list li a {
  display: flex;
  justify-content: space-between;
}

.widget-color .widget-nav-list li {
  display: inline-block;
  margin-bottom: 6px;
  margin-right: 20px;
}
.widget-color .widget-nav-list li:last-child {
  margin-bottom: 0;
}

.tagcloud a::after {
  content: ",";
}

.tagcloud a {
  font-size: 16px;
  padding-right: 5px;
}

.page-pagination li {
  text-align: center;
  padding: 0 5px;
}
.page-pagination li .page-numbers {
  height: 34px;
  padding: 0 13px;
  border-radius: 0;
  text-align: center;
  position: relative;
  color: #000;
  background-color: #f5f5f5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-weight: 500;
  font-size: 12px;
  justify-content: center;
}
.page-pagination li .page-numbers.current {
  background-color: #dcb14a;
}
.page-pagination li .page-numbers i {
  font-size: 12px;
  padding-left: 8px;
}

/*=====  End of Sidebar  ======*/
/*=======================================
=          08. Hero Slider Css           =
=========================================*/
.bg-img {
  background-size: cover !important;
  background-position: center center !important;
  background-repeat: no-repeat !important;
}

.single-hero-slider-one {
  height: 800px;
  display: flex !important;
  align-items: center;
  background-size: cover;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-one {
    height: 600px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-one {
    height: 600px;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-one {
    height: 540px;
  }
}
.single-hero-slider-one .hero-title {
  position: relative;
  padding-bottom: 15px;
}
.single-hero-slider-one .hero-title::after {
  background-color: #dcb14a;
  position: absolute;
  content: "";
  min-height: 4px;
  min-width: 70px;
  max-height: 4px;
  max-width: 70px;
  left: 0;
  bottom: 0;
}
@media only screen and (max-width: 575px) {
  .single-hero-slider-one h6 br {
    display: none;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-one p br {
    display: none;
  }
}
.single-hero-slider-one .hero-btn-one {
  background: #000000;
  color: #ffffff;
  line-height: 38px;
  letter-spacing: 0px;
  font-weight: 500;
  font-size: 15px;
  height: 38px;
  padding: 0 20px;
}
.single-hero-slider-one .hero-btn-one i {
  margin-left: 5px;
}

.hero-slider-one .slick-dots {
  position: absolute;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
}
@media only screen and (max-width: 767px) {
  .hero-slider-one .slick-dots {
    bottom: 2px;
  }
}
.hero-slider-one .slick-dots li {
  display: inline-block;
  margin: 10px;
}
.hero-slider-one .slick-dots li button {
  font-size: 0;
  border: none;
  height: 12px;
  width: 12px;
  background: #ddd;
  border-radius: 100%;
}
.hero-slider-one .slick-dots li.slick-active button {
  background: #dcb14a;
}

.slick-current .hero-text-one {
  margin-top: 50px;
}
.slick-current .hero-text-one h6 {
  animation-name: fadeInLeft;
  animation-duration: 0.4s;
}
.slick-current .hero-text-one h1 {
  animation-name: fadeInLeft;
  animation-duration: 0.6s;
}
.slick-current .hero-text-one p {
  animation-name: fadeInBottom;
  animation-duration: 0.7s;
}
.slick-current .hero-text-one .btn {
  animation-name: fadeInBottom;
  animation-duration: 0.9s;
}

.hero-slider-two .slick-arrow {
  position: absolute;
  top: 50%;
  left: 100px;
  transform: translateY(-50%);
  z-index: 3;
  cursor: pointer;
}
.hero-slider-two .slick-arrow:before {
  content: "";
  display: block;
  width: 103px;
  height: 1px;
  background-color: #666666;
  position: absolute;
  bottom: -4px;
  transition: all 0.5s;
}
.hero-slider-two .slick-arrow.arrow-next {
  right: 100px;
  left: auto;
}
.hero-slider-two .slick-arrow.arrow-next:before {
  right: 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-two .slick-arrow {
    left: 30px;
  }
  .hero-slider-two .slick-arrow.arrow-next {
    right: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-slider-two .slick-arrow {
    left: 15px;
  }
  .hero-slider-two .slick-arrow.arrow-next {
    right: 15px;
  }
  .hero-slider-two .slick-arrow:before {
    content: "";
    display: block;
    width: 40px;
  }
}
.hero-slider-two .slick-arrow:hover {
  color: #000000;
}
.hero-slider-two .slick-arrow:hover:before {
  background-color: #000000;
}

.hero-slider-five .slick-arrow {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  z-index: 3;
  cursor: pointer;
  font-size: 30px;
}
.hero-slider-five .slick-arrow.arrow-next {
  right: 15px;
  left: auto;
}
.hero-slider-five .slick-arrow:hover {
  color: #000000;
}
.hero-slider-five .slick-arrow:hover:before {
  background-color: #000000;
}

.single-hero-slider-five,
.single-hero-slider-nine {
  height: 1100px;
  background: #f1f1f1;
  display: flex !important;
  align-items: center;
  background-size: cover;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-hero-slider-five,
.single-hero-slider-nine {
    height: 900px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-five,
.single-hero-slider-nine {
    height: 850px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-five,
.single-hero-slider-nine {
    height: 700px;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-five,
.single-hero-slider-nine {
    height: 500px;
  }
}
.single-hero-slider-five .hero-content-wrap,
.single-hero-slider-nine .hero-content-wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-top: -256px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-five .hero-content-wrap,
.single-hero-slider-nine .hero-content-wrap {
    margin-top: -156px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-five .hero-content-wrap,
.single-hero-slider-nine .hero-content-wrap {
    margin-top: -156px;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-five .hero-content-wrap,
.single-hero-slider-nine .hero-content-wrap {
    margin-top: 0;
  }
}
.single-hero-slider-five .hero-content-wrap .hero-text-five,
.single-hero-slider-nine .hero-content-wrap .hero-text-five {
  z-index: 99;
}
.single-hero-slider-five .small-title,
.single-hero-slider-nine .small-title {
  position: relative;
  padding-left: 25px;
  color: #999999;
  font-size: 16px;
  font-weight: 400;
}
.single-hero-slider-five .small-title::after,
.single-hero-slider-nine .small-title::after {
  background-color: #999999;
  position: absolute;
  content: "";
  width: 2px;
  height: 100%;
  left: 0;
  bottom: 0;
}
.single-hero-slider-five .hero-title,
.single-hero-slider-nine .hero-title {
  position: absolute;
  padding-bottom: 15px;
}
.single-hero-slider-five .inner-images,
.single-hero-slider-nine .inner-images {
  text-align: center;
  position: absolute;
  right: 0;
}
.single-hero-slider-five .inner-images .image-two,
.single-hero-slider-nine .inner-images .image-two {
  position: absolute;
  bottom: -84px;
  right: 0;
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-five .inner-images .image-two,
.single-hero-slider-nine .inner-images .image-two {
    width: 200px;
    bottom: -4px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-five .inner-images,
.single-hero-slider-nine .inner-images {
    width: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-five .inner-images,
.single-hero-slider-nine .inner-images {
    width: 400px;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-five .inner-images,
.single-hero-slider-nine .inner-images {
    width: 300px;
  }
}

.slick-current .hero-text-five {
  margin-top: 50px;
}
.slick-current .hero-text-five h6 {
  animation-name: fadeInDown;
  animation-duration: 0.4s;
}
.slick-current .hero-text-five h1 {
  animation-name: fadeInUp;
  animation-duration: 0.6s;
}
.slick-current .hero-text-five a {
  animation-name: fadeInUp;
  animation-duration: 0.9s;
}
.slick-current .inner-images .image-one {
  animation-name: fadeInDown;
  animation-duration: 0.6s;
}
.slick-current .inner-images .image-two {
  animation-name: fadeInUp;
  animation-duration: 0.6s;
}

.single-hero-slider-nine {
  height: 880px;
  background: #f1f1f1;
  display: flex !important;
  align-items: center;
  background-size: cover;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-nine {
    height: 750px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-nine {
    height: 700px;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-nine {
    height: 500px;
  }
}
.single-hero-slider-nine .hero-content-wrap {
  margin-top: 0px;
}

.fp-table.active h6 {
  animation-name: fadeInDown;
  animation-duration: 0.4s;
}
.fp-table.active h1 {
  animation-name: fadeInUp;
  animation-duration: 0.6s;
}
.fp-table.active a {
  animation-name: fadeInUp;
  animation-duration: 0.9s;
}

.fullpage-area .section {
  position: relative;
}
.fullpage-area .single-hero-slider-one {
  height: 100%;
}

.fullpage-fotter-area {
  position: absolute;
  bottom: 50px;
  width: 100%;
  z-index: 55;
}
.fullpage-fotter-area .fullpage-social-list li {
  margin-right: 20px;
}
.fullpage-fotter-area h5 span {
  font-size: 18px;
  color: #777;
}

#fp-nav ul {
  position: absolute;
  top: 55%;
  z-index: 3;
  right: 67px;
  padding: 0;
  margin: 0;
  list-style: none;
}

#fp-nav ul {
  position: fixed !important;
  top: 50% !important;
  -webkit-transform: translateY(-50%) !important;
  -ms-transform: translateY(-50%) !important;
  transform: translateY(-50%) !important;
}

#fp-nav ul li a {
  width: 10px;
  height: 10px;
  background-color: #d8dde6;
  display: inline-block;
  border-radius: 100%;
}
#fp-nav ul li a.active {
  background-color: #9fa5b2;
}

.single-hero-slider-7, .single-hero-slider-four {
  height: 600px;
  background: #f1f1f1;
  display: flex !important;
  align-items: center;
  background-size: cover;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-hero-slider-7, .single-hero-slider-four {
    height: 600px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-7, .single-hero-slider-four {
    height: 600px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-7, .single-hero-slider-four {
    height: 600px;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-7, .single-hero-slider-four {
    height: 500px;
  }
}
.single-hero-slider-7 .hero-content-wrap, .single-hero-slider-four .hero-content-wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-left: 100px;
}
.single-hero-slider-7 .hero-content-wrap .hero-text-7, .single-hero-slider-four .hero-content-wrap .hero-text-7 {
  z-index: 99;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-7 .hero-content-wrap, .single-hero-slider-four .hero-content-wrap {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-7 .hero-content-wrap, .single-hero-slider-four .hero-content-wrap {
    margin-left: 0px;
  }
}
.single-hero-slider-7 h6, .single-hero-slider-four h6 {
  position: relative;
  color: #999999;
  font-size: 16px;
  font-weight: 400;
}
.single-hero-slider-7 .inner-images, .single-hero-slider-four .inner-images {
  text-align: center;
  position: absolute;
  right: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-7 .inner-images, .single-hero-slider-four .inner-images {
    width: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-7 .inner-images, .single-hero-slider-four .inner-images {
    width: 400px;
    right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-7 .inner-images, .single-hero-slider-four .inner-images {
    width: 300px;
    right: 0;
  }
}

.hero-slider-7 .slick-arrow, .hero-slider-13 .slick-arrow, .hero-slider-four .slick-arrow {
  position: absolute;
  top: 50%;
  left: 15px;
  transform: translateY(-50%);
  z-index: 3;
  cursor: pointer;
  font-size: 30px;
}
.hero-slider-7 .slick-arrow.arrow-next, .hero-slider-13 .slick-arrow.arrow-next, .hero-slider-four .slick-arrow.arrow-next {
  right: 15px;
  left: auto;
}
.hero-slider-7 .slick-arrow:hover, .hero-slider-13 .slick-arrow:hover, .hero-slider-four .slick-arrow:hover {
  color: #000000;
}
.hero-slider-7 .slick-arrow:hover:before, .hero-slider-13 .slick-arrow:hover:before, .hero-slider-four .slick-arrow:hover:before {
  background-color: #000000;
}

.single-hero-slider-four {
  height: 780px;
  background: #f1f1f1;
  display: flex !important;
  align-items: center;
  background-size: cover;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-hero-slider-four {
    height: 700px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-four {
    height: 600px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-four {
    height: 600px;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-four {
    height: 500px;
  }
}
.single-hero-slider-four .inner-images {
  top: 12%;
}

.hero-slider-four .slider__counter {
  position: absolute;
  left: 50px;
  bottom: 50px;
  font-weight: 600;
  letter-spacing: 6px;
}

.slick-current .hero-text-7 h6 {
  animation-name: fadeInDown;
  animation-duration: 0.4s;
}
.slick-current .hero-text-7 h1 {
  animation-name: fadeInUp;
  animation-duration: 0.7s;
}
.slick-current .hero-text-7 a {
  animation-name: fadeInUp;
  animation-duration: 0.9s;
}
.slick-current .inner-images .image-one {
  animation-name: fadeInRight;
  animation-duration: 0.9s;
}

.hero-slider-8 .col-lg-12 {
  padding-left: 55px;
  padding-right: 55px;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .hero-slider-8 .col-lg-12 {
    padding-left: 25px;
    padding-right: 25px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-8 .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-8 .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-slider-8 .col-lg-12 {
    padding-left: 15px;
    padding-right: 15px;
  }
}
.hero-slider-8 .hero-content-wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
  margin-left: 100px;
}
.hero-slider-8 .hero-content-wrap .hero-text-7 {
  z-index: 99;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-8 .hero-content-wrap {
    margin-left: 50px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-slider-8 .hero-content-wrap {
    margin-left: 50px;
  }
}
@media only screen and (max-width: 575px) {
  .hero-slider-8 .hero-content-wrap {
    margin-left: 30px;
  }
}
.hero-slider-8 .slick-arrow {
  position: absolute;
  top: 50%;
  left: 135px;
  transform: translateY(-50%);
  z-index: 3;
  cursor: pointer;
  font-size: 30px;
}
.hero-slider-8 .slick-arrow.arrow-next {
  right: 135px;
  left: auto;
}
@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-8 .slick-arrow {
    left: 35px;
  }
  .hero-slider-8 .slick-arrow.arrow-next {
    right: 35px;
    left: auto;
  }
}
@media only screen and (max-width: 575px) {
  .hero-slider-8 .slick-arrow {
    display: none;
  }
}
.hero-slider-8 .slick-arrow:hover {
  color: #000000;
}
.hero-slider-8 .slick-arrow:hover:before {
  background-color: #000000;
}

.single-hero-slider-10 {
  height: 900px;
  background: #f1f1f1;
  display: flex !important;
  align-items: center;
  background-size: cover;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-hero-slider-10 {
    height: 800px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-10 {
    height: 600px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-10 {
    height: 550px;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-10 {
    height: 550px;
  }
}
.single-hero-slider-10 .hero-content-wrap {
  display: flex;
  justify-content: space-between;
  position: relative;
}
.single-hero-slider-10 .hero-content-wrap .hero-text-7 {
  z-index: 99;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-10 .hero-content-wrap {
    padding-top: 90px;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-10 .hero-content-wrap {
    padding-top: 60px;
    padding-bottom: 60px;
    margin-left: 0px;
    flex-direction: column;
  }
  .single-hero-slider-10 .hero-content-wrap p {
    display: none;
  }
}
.single-hero-slider-10 h5 {
  position: relative;
  font-weight: 400;
}
.single-hero-slider-10 .inner-images {
  text-align: center;
  position: absolute;
  right: 0px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-10 .inner-images {
    width: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-10 .inner-images {
    width: 400px;
    right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-10 .inner-images {
    width: 300px;
    right: 50px;
    bottom: -80px;
  }
}
@media only screen and (max-width: 575px) {
  .single-hero-slider-10 .inner-images {
    width: 300px;
    right: 0px;
    bottom: -100px;
  }
}

.hero-slider-10 .slick-dots {
  position: absolute;
  right: 100px;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-slider-10 .slick-dots {
    right: 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-slider-10 .slick-dots {
    right: 20px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-slider-10 .slick-dots {
    right: 15px;
  }
}
.hero-slider-10 .slick-dots li {
  margin: 10px 0;
  font-size: 18px;
  color: #666;
}
.hero-slider-10 .slick-dots li .pager__item {
  background: transparent;
  border: none;
  position: relative;
}
.hero-slider-10 .slick-dots li .pager__item::after {
  right: 0;
  bottom: 0;
  content: "";
  height: 2px;
  width: 40px;
  background: #666;
  position: absolute;
  transition: all 0.3s ease-in-out;
}
.hero-slider-10 .slick-dots li.slick-active {
  color: #000000;
}
.hero-slider-10 .slick-dots li.slick-active .pager__item::after {
  width: 60px;
  background: #000000;
}

.hero-product-image {
  position: relative;
  overflow: hidden;
}
.hero-product-image > a {
  display: block;
}
.hero-product-image > a img {
  width: 100%;
}
.hero-product-image > a:hover {
  transform: scale(1.05);
}
.hero-product-image .product-banner-title {
  position: absolute;
  z-index: 99;
  top: 30px;
  left: 30px;
}
.hero-product-image .product-banner-title h4 {
  font-size: 22px;
}
.hero-product-image .product-banner-title h6 {
  font-size: 16px;
}
.hero-product-image:hover h4 {
  color: #999;
}
.hero-product-image:hover h6 {
  color: #dcb14a;
}

.hero-area-video {
  z-index: 2;
}
.hero-area-video.youtube-bg {
  background: url(../images/banners/video-about.webp);
  background-position: center;
  background-repeat: no-repeat;
}
.hero-area-video.dotted-overlay::before {
  background: rgba(0, 0, 0, 0.6);
  content: "";
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
}

.single-hero-slider-12 {
  position: relative;
  height: 100vh;
  display: flex;
  align-items: center;
}
.single-hero-slider-12 .hero-text-12 {
  height: 100%;
}

.single-hero-slider-13 {
  height: 600px;
  background: #f1f1f1;
  display: flex !important;
  align-items: center;
  background-size: cover;
}
@media only screen and (min-width: 1200px) and (max-width: 1499px) {
  .single-hero-slider-13 {
    height: 600px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-13 {
    height: 600px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-13 {
    height: 600px;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-13 {
    height: 500px;
  }
}
.single-hero-slider-13 .hero-content-wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
}
.single-hero-slider-13 .hero-content-wrap .hero-text-7 {
  z-index: 99;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-13 .hero-content-wrap {
    margin-left: 0px;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-13 .hero-content-wrap {
    margin-left: 0px;
  }
}
.single-hero-slider-13 h6 {
  position: relative;
  color: #999999;
  font-size: 16px;
  font-weight: 400;
}
.single-hero-slider-13 .inner-images {
  text-align: center;
  position: absolute;
  right: 50px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .single-hero-slider-13 .inner-images {
    width: 400px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-hero-slider-13 .inner-images {
    width: 400px;
    right: 0;
  }
}
@media only screen and (max-width: 767px) {
  .single-hero-slider-13 .inner-images {
    width: 300px;
    right: 0;
  }
}

/*=======================================
=          09. About Us Css           =
=========================================*/
.hero-about-us-content p {
  font-size: 20px;
  font-family: "Libre Baskerville", serif;
  font-weight: 500;
}

.about-us-content-4 {
  height: 100%;
  padding: 50px;
}

.elendo-video-box {
  background: url(../images/banners/video-banner.webp);
  min-height: 450px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
  -webkit-background-size: cover;
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
}
.elendo-video-box .video-icon a {
  display: block;
  position: absolute;
  left: 50%;
  transform: translateX(-25px);
}
.elendo-video-box .video-icon::before {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  content: "";
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.elendo-video-box:hover {
  transform: scale(1.02);
}
.elendo-video-box:hover .video-icon::before {
  opacity: 1;
}
.elendo-video-box .linear-ic-play {
  font-size: 62px;
  line-height: 60px;
  font-style: normal;
}
.elendo-video-box .linear-ic-play::before {
  content: "I";
  font-family: "ElegantIcons";
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
}

.banner-video-box {
  position: relative;
  overflow: hidden;
}
.banner-video-box::after {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.4);
  content: "";
  opacity: 0;
  transition: all 0.3s ease-in-out;
}
.banner-video-box .video-icon a {
  z-index: 3;
  display: block;
  position: absolute;
  left: 50%;
  top: 40%;
  transform: translate(-50%, -50%);
}
.banner-video-box .video-icon a i {
  font-size: 62px;
  line-height: 60px;
}
.banner-video-box:hover img {
  transform: scale(1.02);
}
.banner-video-box:hover::after {
  opacity: 1;
}
.banner-video-box .linear-ic-play {
  font-style: normal;
}
.banner-video-box .linear-ic-play::before {
  content: "I";
  font-family: "ElegantIcons";
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
}

.about-us-content_6 h2 {
  font-size: 60px;
  font-family: "Mr De Haviland", cursive;
  margin-bottom: 20px;
}
.about-us-content_6 p {
  max-width: 780px;
  margin: auto;
}

.single-support-item {
  margin-top: 50px;
}
.single-support-item .header-support {
  display: flex;
  align-items: center;
}
.single-support-item .header-support .icon {
  line-height: 1;
  font-size: 36px;
  color: #000;
  margin-right: 20px;
}
.single-support-item .iconbox-desc {
  margin-top: 20px;
}

.progress-charts {
  overflow: hidden;
}
.progress-charts h6.heading {
  margin-bottom: 13px;
  text-transform: uppercase;
}
.progress-charts h6.heading span.percent-label {
  color: #222;
  font-weight: 600;
}
.progress-charts h6.custom-color--2 {
  color: #000;
}
.progress-charts h6.custom-color--3 {
  color: #66a7e8;
}
.progress-charts h6.custom-color--4 {
  color: #f10;
}
.progress-charts h6.custom-color--5 {
  color: #23a455;
}
.progress-charts h6.custom-color--6 {
  color: #a0a0a0;
}
.progress-charts h6.custom-color--7 {
  color: #000;
}
.progress-charts h6.custom-color--8 {
  color: #7971ea;
}
.progress-charts .progress {
  height: 3px;
  overflow: visible;
  font-size: 14px;
  background-color: #eee;
  border-radius: 0;
}
.progress-charts .progress .progress-bar {
  overflow: visible;
  position: relative;
  background: #dcb14a;
}
.progress-charts .progress .progress-bar.custom-color--2 {
  background-color: #000;
}
.progress-charts .progress .progress-bar span.percent-label {
  position: absolute;
  color: #222;
  right: -7px;
  top: -35px;
  font-size: 14px;
  font-weight: 600;
}

.prog-title {
  font-size: 14px;
  margin-bottom: 10px;
}

.progress-charts {
  margin-bottom: 20px;
}

.radial-progress-single.progress-flex {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px) {
  .radial-progress-single.progress-flex {
    display: block;
  }
}

/*=======================================
=        10. Product Details Css           =
=========================================*/
.product-details-thumbs-2 {
  float: left;
  width: 110px;
}
.product-details-thumbs-2 .sm-image {
  margin-bottom: 5px;
}
.product-details-thumbs-2 .sm-image:last-child {
  margin-bottom: 0;
}
@media only screen and (max-width: 767px) {
  .product-details-thumbs-2 {
    float: inherit;
    display: flex;
    width: auto;
    justify-content: center;
    margin: 0 -5px;
  }
  .product-details-thumbs-2 .sm-image {
    padding: 5px;
  }
}

.product-details-images-2 {
  float: right;
  width: calc(100% - 110px);
  padding-left: 10px;
}
.product-details-images-2 .lg-image a {
  display: block;
}
.product-details-images-2 .lg-image a img {
  width: 100%;
}
@media only screen and (max-width: 767px) {
  .product-details-images-2 {
    float: inherit;
    width: calc(100% - 0px);
    padding-left: 0px;
  }
}

.product-details-content .price {
  color: #999;
  font-weight: 400;
  font-size: 30px;
}
.product-details-content .on-sale-price {
  font-weight: 400;
}
.product-details-content .on-sale-price .new-price {
  color: #df0303;
  font-size: 30px;
  margin-right: 18px;
}
.product-details-content .on-sale-price .old-price {
  font-size: 20px;
  text-decoration: line-through;
  color: #999;
  margin-top: 5px;
}
.product-details-content .stock {
  font-size: 16px;
  font-weight: 500;
  margin: 20px 0 15px;
}
.product-details-content .stock p {
  color: #000000;
}
.product-details-content .stock.in-stock p span {
  color: #3bc604;
}
.product-details-content .stock.out-of-stock p span {
  color: #df0303;
}

.product-details-scroll-images .lg-image {
  margin-bottom: 30px;
}
.product-details-scroll-images .lg-image:last-child {
  margin-bottom: 0;
}
.product-details-scroll-images .lg-image a {
  display: block;
}
.product-details-scroll-images .lg-image a img {
  width: 100%;
}

.content-center .product-variants {
  display: flex;
  justify-content: center;
}
.content-center .cart-quantity {
  display: flex;
  align-items: end;
  justify-content: center;
}

.group-cart-table .table td {
  text-align: center;
}
.group-cart-table .quantity-field label {
  margin-right: 5px;
}
.group-cart-table .quantity-field input {
  width: 50px;
  text-align: center;
}
@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .group-cart-table .table td {
    min-width: 200px;
  }
}

.product-details-tab .nav {
  border-bottom: 1px solid #ddd;
}
.product-details-tab .nav li {
  margin: 0px 0px;
  position: relative;
  padding: 0 20px;
  margin-bottom: 20px;
}
.product-details-tab .nav li::before {
  content: "/";
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  position: absolute;
}
.product-details-tab .nav li:last-child::before {
  display: none;
}
.product-details-tab .nav li:first-child {
  padding-left: 0;
}
.product-details-tab .nav li a {
  font-weight: 500;
  font-size: 18px;
  display: block;
  position: relative;
}
@media only screen and (max-width: 575px) {
  .product-details-tab .nav li a {
    padding-bottom: 10px;
  }
  .product-details-tab .nav li a:last-child {
    margin-top: 10px;
  }
}
.product-details-tab .nav li:hover > a {
  width: 100%;
}

.product_details_container {
  margin: -5px -10px;
}

.single-product-gallery {
  float: left;
  padding: 5px 10px;
  width: 50%;
}

.comments-reply-area .comment-input .comment-form-author, .comments-reply-area .comment-input .comment-form-email {
  width: 50%;
}
@media only screen and (max-width: 575px) {
  .comments-reply-area .comment-input .comment-form-author, .comments-reply-area .comment-input .comment-form-email {
    width: 100%;
  }
}

.feature_list li {
  margin-bottom: 5px;
}
.feature_list li i {
  margin-right: 10px;
  text-align: center;
  font-size: 16px;
}

.comment-form-comment .comment-notes {
  width: 100%;
  padding: 10px;
  border: 1px solid #cfcfcf;
  height: 140px;
}
.comment-form-comment label {
  display: block;
  margin-bottom: 1px;
}
.comment-form-comment label span {
  color: #dcb14a;
}

.comment-form-author, .comment-form-email, .comment-form-url {
  float: left;
  padding: 0 10px;
  width: 33.3333%;
}
@media only screen and (max-width: 575px) {
  .comment-form-author, .comment-form-email, .comment-form-url {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .comment-form-author, .comment-form-email, .comment-form-url {
    width: 100%;
  }
}

.comment-input {
  margin: 0 -10px;
  overflow: hidden;
}
.comment-input label {
  display: block;
  margin-bottom: 1px;
}
.comment-input input {
  width: 100%;
  padding: 10px;
  border: 1px solid #cfcfcf;
  height: 40px;
}

.comment-form-submit .comment-submit {
  background: #dcb14a;
  color: #ffffff;
  border: none;
  padding: 8px 15px;
  margin-top: 20px;
}
.comment-form-submit .comment-submit:hover {
  background: #000000;
}

.product-details-wrap {
  border-bottom: 1px solid #ddd;
  margin-bottom: 30px;
  padding-bottom: 30px;
}

.shop_attributes {
  width: 100%;
}
.shop_attributes tr {
  width: 50%;
  float: left;
}

.comments-reply-area .comment-input .comment-form-author, .comments-reply-area .comment-input .comment-form-email {
  width: 50%;
}
@media only screen and (max-width: 575px) {
  .comments-reply-area .comment-input .comment-form-author, .comments-reply-area .comment-input .comment-form-email {
    width: 100%;
  }
}

.comment-form-submit .comment-submit {
  background: #000000;
  color: #ffffff;
  border: none;
  padding: 4px 28px;
  margin-top: 20px;
}
.comment-form-submit .comment-submit:hover {
  background: #444;
}

.blog-wrap-col-3 .blog-contend h3 {
  font-size: 18px;
}
.blog-wrap-col-3 .blog-contend .blog-date-categori {
  margin-bottom: 10px;
}
.blog-wrap-col-3 .blog-contend .blog-date-categori ul li {
  display: inline-block;
}
.blog-wrap-col-3 .blog-contend .blog-date-categori ul li a {
  font-size: 14px;
  margin-right: 10px;
  font-weight: 400;
}
.blog-wrap-col-3 .blog-contend .blog-date-categori ul li a i {
  font-size: 14px;
  margin-right: 5px;
}
.blog-wrap-col-3 .blog-btn {
  display: inline-block;
  padding: 5px 15px;
}

.product-rating {
  display: flex;
  font-size: 14px;
  display: flex;
  margin-right: 8px;
  color: #ddd;
}
.product-rating .yellow {
  color: #F5A623;
}
.product-rating i {
  margin: 0;
}

.pro_review {
  display: flex;
}
.pro_review.ans {
  margin-bottom: 50px;
  margin-left: 50px;
  margin-top: 50px;
}
@media only screen and (max-width: 575px) {
  .pro_review.ans {
    margin-bottom: 30px;
    margin-left: 20px;
    margin-top: 30px;
  }
}

.review_thumb {
  margin-right: 20px;
  min-width: 8%;
}
@media only screen and (max-width: 767px) {
  .review_thumb {
    min-width: 25%;
  }
}

.review_info h4 {
  font-weight: 500;
}

.rating_send {
  border: 1px solid #dddddd;
  display: block;
  float: right;
  font-size: 20px;
  margin-top: -55px;
  padding: 2px 13px;
}
.rating_send a {
  font-size: 14px;
}

.review_date {
  margin-top: 5px;
}

.review_details p {
  margin-top: 5px;
}

.product-color-wrapper, .product-size-wrapper {
  color: #666;
  font-weight: 500;
  position: relative;
}
.product-color-wrapper label, .product-size-wrapper label {
  color: #000000;
}

.product-size-menu li {
  margin-right: 20px;
}
.product-size-menu li a {
  padding: 0;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: #f1f1f1;
  border-radius: 50%;
  border: 1px solid transparent;
  text-align: center;
  color: #333;
}
.product-size-menu li a.active {
  border: 1px solid #bfbfbf;
}

.product-color-menu li {
  margin-right: 20px;
}
.product-color-menu li a {
  padding: 0;
  height: 30px;
  width: 30px;
  line-height: 30px;
  background: #f1f1f1;
  border-radius: 50%;
  border: 1px solid transparent;
  text-align: center;
  color: #333;
  position: relative;
}
.product-color-menu li a.active {
  background: #333;
}
.product-color-menu li a.active::after {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  transform: rotate(45deg);
  content: "";
  width: 6px;
  height: 10px;
  display: block;
  border: solid #eee;
  border-width: 0 2px 2px 0;
  position: absolute;
  top: 8px;
  left: 11px;
}

@media only screen and (max-width: 575px) {
  .quickview-button {
    margin-top: 10px;
  }
}

.variations select {
  width: 100%;
  height: 45px;
  line-height: 45px;
  border-color: #bfbfbf;
  border-radius: 0;
  padding: 0 15px;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  color: #000;
  cursor: pointer;
  background: #fff url("../images/icons/selector-icon.webp") no-repeat center right 20px;
}
.variations select:focus {
  background: #f8f8f8 url("../images/icons/selector-icon.webp") no-repeat center right 20px !important;
}

.groupped-table {
  width: 100%;
}
.groupped-table thead th {
  background-color: #f4f5f7;
  border: none;
  color: #000;
  font-weight: 500;
  text-transform: uppercase;
  padding: 12px 15px;
  width: 50%;
}
.groupped-table .product-quantity {
  text-align: right;
}
.groupped-table .product-groupped-item .product-title {
  font-size: 14px;
  width: max-content;
  font-weight: 500;
  color: #000;
  display: block;
}
.groupped-table .product-groupped-item .price {
  color: #999;
  font-size: 16px;
  line-height: 1;
  margin-top: 5px;
}
.groupped-table tr {
  border: 0;
  border-bottom: 1px solid #eaeaea;
}
.groupped-table .single-groupped-item th {
  padding: 15px 15px 14px;
  color: #666;
  width: inherit;
  text-align: left;
  border: none;
}
.groupped-table .single-groupped-item .quickview-quality {
  text-align: right;
}
.groupped-table .single-groupped-item input.input-text {
  width: 45px;
  border: 1px solid #ddd;
  padding: 2px 5px;
}

.product-details-images-horizontal {
  overflow: hidden;
}
.product-details-images-horizontal .easyzoom-style {
  overflow: hidden;
}

.product-details-thumbs-horizontal {
  position: relative;
  overflow: hidden;
}
.product-details-thumbs-horizontal .sm-image {
  margin: 0 15px;
}
.product-details-thumbs-horizontal .slick-arrow {
  position: absolute;
  top: 50%;
  left: 0;
  height: 30px;
  width: 30px;
  background: #ddd;
  text-align: center;
  line-height: 30px;
  border-radius: 5px;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
}
.product-details-thumbs-horizontal .slick-arrow.arrow-next {
  right: 0;
  left: auto;
}
.product-details-thumbs-horizontal .slick-arrow:hover {
  background-color: #dcb14a;
}

/*=======================================
=        11. Shop Pages Css           =
=========================================*/
.col__20 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}

.col__20 {
  width: 20%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .col__20 {
    width: 25%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .col__20 {
    width: 33.333%;
  }
}
@media only screen and (max-width: 767px) {
  .col__20 {
    width: 50%;
  }
}
@media only screen and (max-width: 575px) {
  .col__20 {
    width: 100%;
  }
}

.plr-0 {
  padding-right: 0px;
  padding-left: 0px;
}

.shop-toolbar__items-wrapper, .shop-toolbar__item--left {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.shop-toolbar__items-wrapper .shop-toolbar__item, .shop-toolbar__item--left .shop-toolbar__item {
  border-right: 1px solid #000;
  line-height: 12px;
  cursor: pointer;
  display: block;
  padding: 0 17px;
}
.shop-toolbar__items-wrapper .shop-toolbar__item:last-child, .shop-toolbar__item--left .shop-toolbar__item:last-child {
  border-right: none;
}
.shop-toolbar__items-wrapper .shop-toolbar__item:last-child, .shop-toolbar__item--left .shop-toolbar__item:last-child {
  padding-right: 0;
}
.shop-toolbar__items-wrapper .shop-toolbar__item:first-child, .shop-toolbar__item--left .shop-toolbar__item:first-child {
  padding-left: 0;
}
@media only screen and (max-width: 767px) {
  .shop-toolbar__items-wrapper, .shop-toolbar__item--left {
    margin-bottom: 20px;
    justify-content: center;
  }
}

.shop-toolbar__item--left {
  justify-content: flex-start;
}
@media only screen and (max-width: 767px) {
  .shop-toolbar__item--left {
    justify-content: center;
  }
}

.toolber-tab-menu li {
  padding: 0 10px;
}
.toolber-tab-menu li a {
  padding: 0;
}
.toolber-tab-menu li a img {
  width: 1em;
  height: 1em;
  opacity: 0.5;
}
.toolber-tab-menu li a.active {
  color: #999;
}
.toolber-tab-menu li a.active img {
  opacity: 1;
}

.shop-toolbar__item--filter i {
  padding-left: 8px;
  font-size: 12px;
  font-weight: 700;
}

.product-filter-wrapper {
  margin-bottom: 30px;
  font-size: 14px;
  padding: 40px 15px 10px;
  display: none;
  width: 100%;
}
.product-filter-wrapper .product-filter h5 {
  font-size: 18px;
  margin: 0 0 17px;
  text-transform: capitalize;
  font-weight: 700;
}
@media only screen and (max-width: 575px) {
  .product-filter-wrapper .product-filter h5 {
    margin: 0 0 10px;
  }
}
.product-filter-wrapper .product-filter .widget-nav-list li {
  position: relative;
  margin-bottom: 8px;
  padding: 1px 0;
  display: block;
  font-size: 16px;
}
.product-filter-wrapper .product-filter .price-filter ul li {
  margin-bottom: 10px;
}
.product-filter-wrapper .product-filter .product-tags ul {
  width: 100%;
}
.product-filter-wrapper .product-filter .product-tags ul li {
  width: 50%;
  display: inline-block;
  float: left;
}
.product-filter-wrapper .product-filter .fliter-size ul li {
  display: inline-block;
  margin-right: 9px;
  margin-bottom: 10px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper .product-filter .fliter-size ul li {
    margin-right: 2px;
  }
}
.product-filter-wrapper .product-filter .fliter-size ul li:last-child {
  margin-right: 0px;
}
.product-filter-wrapper .product-filter .fliter-size ul li a {
  color: #535353;
  font-size: 12px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 9px 16px;
  display: block;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper .product-filter .fliter-size ul li a {
    padding: 3px 8px;
  }
}
.product-filter-wrapper .product-filter .fliter-size ul li a:hover {
  color: #fff;
  border: 1px solid #dcb14a;
  background-color: #dcb14a;
}
.product-filter-wrapper .product-filter .tagcloud a {
  font-size: 16px;
  padding-right: 5px;
}
.product-filter-wrapper .product-filter .tagcloud a::after {
  content: ",";
}
.product-filter-wrapper .filter-close-wrap a {
  font-size: 16px;
  color: #dcb14a;
}
.product-filter-wrapper .filter-close-wrap a i {
  position: relative;
  top: 3px;
  font-size: 20px;
  margin-right: 3px;
}
.product-filter-wrapper .col__20 {
  width: 20%;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .product-filter-wrapper .col__20 {
    width: 25%;
  }
}
@media only screen and (max-width: 767px) {
  .product-filter-wrapper .col__20 {
    width: 25%;
  }
}
@media only screen and (max-width: 575px) {
  .product-filter-wrapper .col__20 {
    width: 50%;
  }
}

.swatch-color {
  width: 18px;
  height: 18px;
  display: inline-block;
  border-radius: 50%;
  opacity: 0.5;
  margin-right: 10px;
}
.swatch-color.black {
  background-color: #000000;
}
.swatch-color.green {
  background-color: #81d742;
}
.swatch-color.grey {
  background-color: #adadad;
}
.swatch-color.red {
  background-color: #dd3333;
}
.swatch-color.white {
  background-color: #f4f4f4;
}
.swatch-color.yellow {
  background-color: #eeee22;
}

.shop-short-by {
  position: relative;
  padding: 10px 0;
  line-height: 1;
}
@media only screen and (max-width: 767px) {
  .shop-short-by {
    padding: 30px 0;
  }
}
.shop-short-by > ul > li {
  position: relative;
  transition: all 0.3s ease-in-out;
}
.shop-short-by > ul > li a {
  color: #000000;
}
.shop-short-by > ul > li a i {
  color: #282828;
  font-weight: 700;
  margin-left: 3px;
  transition: all 0.4s ease 0s;
}
.shop-short-by > ul > li a i.angle-up {
  position: absolute;
  right: 3px;
  top: 1px;
  opacity: 0;
  visibility: hidden;
}
.shop-short-by > ul > li > ul {
  left: 0;
  top: 100%;
  position: absolute;
  background-color: #fff;
  padding: 10px;
  z-index: 11;
  visibility: hidden;
  opacity: 0;
  border: 1px solid #ddd;
  transition: all 250ms ease-out;
  margin-top: 30px;
}
@media only screen and (max-width: 767px) {
  .shop-short-by > ul > li > ul {
    right: 0;
    left: auto;
  }
}
.shop-short-by > ul > li > ul li {
  display: block;
}
.shop-short-by > ul > li > ul li a {
  padding: 5px 10px;
  white-space: pre;
  display: block;
  border-radius: 4px;
  font-size: 0.92em;
  color: #777777;
  line-height: 24px;
}
.shop-short-by > ul > li > ul li a:hover {
  color: #222;
}
.shop-short-by > ul > li > ul li.active a {
  color: #222;
}
.shop-short-by > ul > li:hover ul {
  opacity: 1;
  visibility: visible;
  margin-top: 10px;
}

.product-border .product-thumbnail {
  border-width: 1px;
  border-style: solid;
  border-color: #dadada;
  border-width: 1px 0 1px 1px;
}
.product-border:last-child .product-thumbnail {
  border-width: 1px;
}
.product-border .col__20 {
  padding-right: 0px;
  padding-left: 0px;
}

.gx-0-coustom .col__20 {
  padding-right: 0px;
  padding-left: 0px;
}

.pagination-box {
  display: flex;
  margin-top: 60px;
  justify-content: center;
  text-align: center;
}
.pagination-box li {
  margin-right: 10px;
}
.pagination-box li:last-child {
  margin-right: 0;
}
.pagination-box li a {
  height: 38px;
  width: 36px;
  line-height: 40px;
  background-color: #dadada;
  border-radius: 5px;
  text-align: center;
}
.pagination-box li a:hover {
  background-color: #dcb14a;
  color: #fff;
}

/*==============================================
=         12. Cart & Checkout Pages Css          =
================================================*/
h3.cart-page-title {
  font-size: 20px;
  font-weight: 500;
  margin: 0 0 15px;
}

.header-color-gray.cart-table-content table thead > tr th {
  padding: 10px 0;
}
.header-color-gray.cart-table-content table tbody > tr td {
  padding: 20px 0px 20px;
}

.cart-table-content table {
  width: 100%;
}
.cart-table-content table thead > tr th {
  border-top: medium none;
  font-size: 18px;
  text-transform: capitalize;
  vertical-align: middle;
  white-space: nowrap;
  color: #000000;
  letter-spacing: 1px;
  font-weight: 500;
  text-transform: capitalize;
  padding: 0 0 33px 0;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .cart-table-content table thead > tr th {
    padding: 0 0 33px 0;
  }
}
@media only screen and (max-width: 767px) {
  .cart-table-content table thead > tr th {
    padding: 0 20px 33px 20px;
  }
}
.cart-table-content table thead > tr th .product-name {
  max-width: 400px;
}
.cart-table-content table thead > tr th .product-price {
  max-width: 300px;
}
.cart-table-content table tbody > tr td {
  font-size: 14px;
  padding: 40px 0px 40px;
  border-top: 1px solid #ddd;
}
.cart-table-content table tbody > tr td.product-remove {
  width: 50px;
}
.cart-table-content table tbody > tr td.product-remove a {
  color: #777777;
}
.cart-table-content table tbody > tr td.product-img {
  width: 100px;
}
.cart-table-content table tbody > tr td.product-name {
  width: 370px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .cart-table-content table tbody > tr td.product-name {
    min-width: 250px;
  }
}
@media only screen and (max-width: 575px) {
  .cart-table-content table tbody > tr td.product-name {
    min-width: 200px;
    padding-left: 20px;
    padding-right: 0px;
  }
}
.cart-table-content table tbody > tr td.product-name a {
  font-size: 16px;
  font-weight: 500;
}
.cart-table-content table tbody > tr td.product-name a:hover {
  color: #dcb14a;
}
.cart-table-content table tbody > tr td.product-price {
  min-width: 250px;
}
.cart-table-content table tbody > tr td.product-price span {
  color: #1f2226;
  font-weight: 500;
}
.cart-table-content table tbody > tr td.product-stock-status {
  min-width: 180px;
  font-weight: 500;
}
.cart-table-content table tbody > tr td.product-stock-status p {
  color: #690;
  font-size: 16px;
}
.cart-table-content table tbody > tr td.product-total {
  min-width: 70px;
}
.cart-table-content table tbody > tr td.product-total span {
  font-weight: 600;
}
.cart-table-content table tbody > tr td.price-total {
  min-width: 70px;
}
.cart-table-content table tbody > tr td.price-total span {
  font-weight: 600;
}
.cart-table-content table tbody > tr td.product-wishlist-cart {
  text-align: right;
  min-width: 250px;
}
.cart-table-content table tbody > tr td.product-wishlist-cart > a {
  background-color: #232323;
  color: #fff;
  font-size: 16px;
  line-height: 1;
  padding: 14px 25px;
  text-transform: capitalize;
}
.cart-table-content table tbody > tr td.product-wishlist-cart > a:hover {
  background-color: #dcb14a;
}

.cart-shiping-update-wrapper {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #CDCDCD;
  padding: 18px 0 0;
}
@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper {
    display: block;
  }
}
.cart-shiping-update-wrapper .discount-code {
  position: relative;
  flex: 0 0 50%;
}
.cart-shiping-update-wrapper .discount-code input {
  padding: 0 115px 0 0;
  border: none;
  height: 34px;
  font-size: 12px;
  color: #282828;
  background-color: transparent;
  border-bottom: 1px solid #D8D8D8;
}
.cart-shiping-update-wrapper .discount-code button {
  position: absolute;
  right: 0;
  top: 50%;
  transform: translateY(-50%);
  border: none;
  padding: 0;
  background-color: transparent;
  font-weight: 600;
  font-size: 14px;
}
.cart-shiping-update-wrapper .cart-clear a {
  font-weight: 600;
  display: inline-block;
  margin: 3px 0 0 7px;
}
@media only screen and (max-width: 767px) {
  .cart-shiping-update-wrapper .cart-clear {
    margin-top: 20px;
  }
}

.grand-total-wrap {
  border: 1px solid #bfbfbf;
  background: #F9F9F9;
  padding: 0px 30px 0;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .grand-total-wrap {
    margin-top: 40px;
  }
}
.grand-total-wrap h4 {
  font-weight: 800;
  font-size: 14px;
  margin: 0;
}
.grand-total-wrap .grand-total-content {
  padding: 30px 0 30px;
}
.grand-total-wrap .grand-total-content ul li {
  font-weight: bold;
  border-bottom: 1px solid #CDCDCD;
  padding-bottom: 16px;
  margin-bottom: 17px;
}
.grand-total-wrap .grand-total-content ul li:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}
.grand-total-wrap .grand-total-content ul li span {
  float: right;
  font-weight: 400;
}
.grand-total-wrap .grand-btn {
  margin-left: -30px;
  margin-right: -30px;
}
.grand-total-wrap .grand-btn a {
  background-color: #262626;
  color: #fff;
  text-align: center;
  font-size: 14px;
  display: block;
  padding: 23px 10px 20px;
}
.grand-total-wrap .grand-btn a:hover {
  background-color: #dcb14a;
}

.discount-code {
  max-width: 400px;
}
.discount-code input {
  width: 100%;
  margin-bottom: 30px;
  border: 1px solid #ccc;
  color: #000;
  border-radius: 0;
  padding: 15px 15px 13px;
}

.cart_totals {
  max-width: 400px;
}

/*-------- 26. Checkout style ---------*/
.customer-zone > p {
  position: relative;
  padding: 14px 30px 14px 60px;
  background-color: #f4f5f7;
  margin: 0;
}
.customer-zone > p::before {
  content: "\f058";
  color: #6dde6d;
  display: inline-block;
  position: absolute;
  font-family: fontawesome;
  left: 30px;
}

.checkout-login-info {
  border: 1px solid #ddd;
  padding: 30px;
  display: none;
  margin-top: 30px;
}
.checkout-login-info p {
  color: #777;
  margin: 0 0 22px;
}
.checkout-login-info .single-input input {
  border: 1px solid #CDCDCD;
  height: 50px;
  background-color: transparent;
  width: 100%;
  color: #777;
  font-size: 14px;
  padding: 0 20px;
}
.checkout-login-info .single-input input:focus {
  border: 1px solid #262626;
}
.checkout-login-info .single-input label {
  display: block;
  margin: 0 0 10px;
}
.checkout-login-info .single-input label span {
  color: red;
}

.checkout-coupon-info {
  border: 1px solid #ddd;
  padding: 30px;
  display: none;
  margin-top: 30px;
}
.checkout-coupon-info p {
  color: #777;
  margin: 0 0 22px;
}
.checkout-coupon-info form input[type=text] {
  border: 1px solid #CDCDCD;
  height: 50px;
  background-color: transparent;
  width: 100%;
  color: #777;
  font-size: 14px;
  padding: 0 20px;
}
.checkout-coupon-info form input[type=text]:focus {
  border: 1px solid #262626;
}
.checkout-coupon-info form input[type=submit] {
  background: #262626 none repeat scroll 0 0;
  border: medium none;
  color: #fff;
  height: 50px;
  margin-top: 15px;
  padding: 5px 30px 7px;
  text-transform: capitalize;
  font-weight: 400;
  width: inherit;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .checkout-coupon-info form input[type=submit] {
    padding: 5px 15px;
  }
}
@media only screen and (max-width: 767px) {
  .checkout-coupon-info form input[type=submit] {
    padding: 5px 16px;
    margin-left: 5px;
  }
}
.checkout-coupon-info form input[type=submit]:hover {
  background-color: #dcb14a;
}

.billing-info-wrap .billing-info label {
  display: block;
  margin: 0 0 10px;
}
.billing-info-wrap .billing-info label span {
  color: red;
}
.billing-info-wrap .billing-info input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #777;
  width: 100%;
}
.billing-info-wrap .billing-info input:focus {
  border: 1px solid #262626;
}
.billing-info-wrap .billing-info input.billing-address {
  margin-bottom: 20px;
}
.billing-info-wrap .billing-select label {
  display: block;
  margin: 0 0 10px;
}
.billing-info-wrap .billing-select label span {
  color: red;
}
.billing-info-wrap .billing-select .select-active {
  border: 1px solid #e6e6e6;
  color: #262626;
  border-radius: 0;
  background: transparent url(../images/icons/selector-icon.webp) no-repeat center right 20px !important;
}
.billing-info-wrap .billing-select .select-active:focus {
  background: transparent;
}
.billing-info-wrap .billing-select .select2-container {
  display: block;
}
.billing-info-wrap .billing-select .select2-container .select2-choice {
  border: 1px solid #e6e6e6;
  background: transparent;
  -moz-appearance: none;
  height: 50px;
  padding: 2px 20px;
  color: #262626;
  cursor: pointer;
}
.billing-info-wrap .billing-select .select2-container .select2-choice .select2-chosen {
  height: 50px;
}
.billing-info-wrap .additional-info-wrap {
  margin: 3px 0 0;
}
.billing-info-wrap .additional-info-wrap label {
  display: block;
  margin: 0 0 10px;
  padding: 5px 0 0;
}
.billing-info-wrap .additional-info-wrap textarea {
  min-height: 120px;
  background-color: transparent;
  border-color: #e8e8e8;
  padding: 20px;
  color: #8a8a8a;
  width: 100%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .billing-info-wrap.mr-100 {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}
@media only screen and (max-width: 575px) {
  .billing-info-wrap.mr-100 {
    margin-right: 0px;
  }
}

.select2-container--default .select2-selection--single {
  border: 1px solid #e6e6e6;
  border-radius: 0px;
  cursor: pointer;
  display: block;
  height: 50px;
  user-select: none;
  -webkit-user-select: none;
  line-height: 50px;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
  height: 50px;
  color: #262626;
  line-height: 50px;
  padding: 0px 20px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
  height: 50px;
}

.select2-container--default .select2-search--dropdown .select2-search__field {
  height: 34px;
  background-color: transparent;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: #0073aa;
  color: #fff;
}

.your-order-area {
  padding: 40px 45px 50px;
  border-width: 2px;
  border-style: solid;
  position: relative;
  border: 1px solid #bfbfbf;
  background-color: #f6f6f6;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .your-order-area {
    padding: 40px 25px 50px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .your-order-area {
    margin-top: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .your-order-area {
    padding: 40px 12px 50px;
    margin-top: 30px;
  }
}
.your-order-area h3 {
  font-size: 20px;
  font-weight: 700;
  position: relative;
  margin: 0 0 45px;
}
.your-order-area h3:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: -14px;
  background-color: #000;
  width: 50px;
  height: 2px;
}
.your-order-area .your-order-wrap .your-order-info {
  border-bottom: 1px solid #CDCDCD;
}
.your-order-area .your-order-wrap .your-order-info ul li {
  padding: 0 0 7px;
}
.your-order-area .your-order-wrap .your-order-info ul li span {
  float: right;
}
.your-order-area .your-order-wrap .your-order-info.order-subtotal {
  padding: 15px 0 10px;
}
.your-order-area .your-order-wrap .your-order-info.order-subtotal ul li span {
  font-size: 16px;
}
.your-order-area .your-order-wrap .your-order-info.order-total {
  padding: 15px 0 10px;
}
.your-order-area .your-order-wrap .your-order-info.order-total ul li span {
  font-size: 18px;
}
.your-order-area .your-order-wrap .your-order-middle {
  border-bottom: 1px solid #CDCDCD;
  padding: 24px 0 23px;
}
.your-order-area .your-order-wrap .your-order-middle ul li {
  font-size: 14px;
  margin: 0 0 11px;
}
.your-order-area .your-order-wrap .your-order-middle ul li:last-child {
  margin-bottom: 0;
}
.your-order-area .your-order-wrap .your-order-middle ul li span {
  float: right;
  font-size: 16px;
}
.your-order-area .your-order-wrap .payment-method {
  margin: 25px 0 0;
}
.your-order-area .your-order-wrap .payment-method h5 {
  text-transform: uppercase;
}
.your-order-area .your-order-wrap .payment-method p {
  color: #777;
  font-size: 12px;
  margin: 14px 0 0;
}
.your-order-area .your-order-wrap .condition-wrap {
  margin: 40px 0 0;
}
.your-order-area .your-order-wrap .condition-wrap p {
  color: #777;
  line-height: 1.88;
  margin: 0;
}
.your-order-area .your-order-wrap .condition-wrap p a {
  color: #777;
}
.your-order-area .your-order-wrap .condition-wrap .condition-form input {
  border: 1px solid #9fa0a2;
  display: inline-block;
  height: 10px;
  width: 10px;
  position: relative;
  top: 6px;
}
.your-order-area .your-order-wrap .condition-wrap .condition-form span {
  color: #262626;
  font-weight: 400;
  margin: 0 0 0 5px;
}
.your-order-area .your-order-wrap .condition-wrap .condition-form span span.star {
  color: red;
}
.your-order-area .Place-order a {
  background-color: #262626;
  display: block;
  cursor: pointer;
  padding: 22px 50px 21px;
  width: 100%;
  color: #fff;
  text-align: center;
}
.your-order-area .Place-order a:hover {
  background-color: #dcb14a;
}

.order-tracking-form-box input {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #777;
  width: 100%;
}
.order-tracking-form-box input:focus {
  border: 1px solid #262626;
}
.order-tracking-form-box input.billing-address {
  margin-bottom: 20px;
}

/*==============================================
=         13. Contact Us Pages Css          =
================================================*/
.single-contact-info-item {
  margin-top: 30px;
}
.single-contact-info-item .icon {
  float: left;
  margin-right: 26px;
}
.single-contact-info-item .icon i {
  line-height: 1;
  font-size: 36px;
  color: #000;
}
.single-contact-info-item .iconbox-desc {
  display: inline-block;
}

.contact-input {
  margin: 0 -10px;
}

.contact-input .contact-inner {
  float: left;
  margin: 0 0 20px;
  padding: 0 10px;
  width: 50%;
}

@media only screen and (max-width: 575px) {
  .contact-input .contact-inner {
    width: 100%;
  }
}
.contact-inner {
  margin: 0 0 20px;
}

.contact-inner {
  margin: 0 0 20px;
}
.contact-inner input, .contact-inner textarea {
  border: 1px solid #e8e8e8;
  height: 50px;
  background-color: transparent;
  padding: 2px 20px;
  color: #777;
  width: 100%;
}
.contact-inner input:focus, .contact-inner textarea:focus {
  border: 1px solid #262626;
}
.contact-inner textarea {
  height: 150px;
  padding: 15px;
}
.contact-inner select {
  color: #777;
}

.googleMap-1 {
  height: 600px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .googleMap-1 {
    height: 300px;
  }
}
@media only screen and (max-width: 767px) {
  .googleMap-1 {
    height: 300px;
  }
}

/*====================
    5.5 My Account Page CSS
====================*/
.myaccount-tab-menu {
  flex-direction: column;
}
.myaccount-tab-menu a {
  border: 1px solid #ddd;
  border-bottom: none;
  color: #222;
  font-weight: 500;
  display: block;
  padding: 10px 15px;
  text-transform: uppercase;
}
.myaccount-tab-menu a:last-child {
  border-bottom: 1px solid #ddd;
}
.myaccount-tab-menu a:hover, .myaccount-tab-menu a.active {
  background-color: #dcb14a;
  border-color: #dcb14a;
  color: #ffffff;
}
.myaccount-tab-menu a i {
  text-align: center;
  width: 25px;
}

@media only screen and (max-width: 767px) {
  #myaccountContent {
    margin-top: 30px;
  }
}
.myaccount-content {
  border: 1px solid #ddd;
  padding: 30px;
}
@media only screen and (max-width: 575px) {
  .myaccount-content {
    padding: 20px 15px;
  }
}
.myaccount-content form {
  margin-top: -20px;
}
.myaccount-content form .single-input-item {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.myaccount-content form .single-input-item input {
  width: 100%;
  padding: 12px 10px;
  border: 1px solid #ddd;
  font-size: 14px;
}
.myaccount-content form .single-input-item input:focus {
  border: 1px solid #dcb14a;
}
.myaccount-content form .single-input-item .required {
  font-weight: 500;
}
.myaccount-content form .single-item-button {
  width: 25%;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .myaccount-content form .single-item-button {
    width: 30%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .myaccount-content form .single-item-button {
    width: 45%;
  }
}
@media only screen and (max-width: 767px) {
  .myaccount-content form .single-item-button {
    width: 100%;
  }
}
.myaccount-content .title {
  font-size: 24px;
  color: #222;
  border-bottom: 1px dashed #ddd;
  padding-bottom: 10px;
  margin-bottom: 25px;
  font-weight: 500;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .myaccount-content .title {
    font-size: 20px;
  }
}
.myaccount-content .welcome a {
  color: #222;
}
.myaccount-content .welcome a:hover {
  color: #dcb14a;
}
.myaccount-content .welcome strong {
  font-weight: 600;
  color: #dcb14a;
}
.myaccount-content fieldset {
  margin-top: 20px;
}
.myaccount-content fieldset legend {
  color: #222;
  font-size: 20px;
  margin-top: 20px;
  font-weight: 500;
  border-bottom: 1px dashed #ddd;
}

.myaccount-table {
  white-space: nowrap;
  font-size: 14px;
}
.myaccount-table table th, .myaccount-table .table th {
  color: #222;
  padding: 10px;
  font-weight: 500;
  background-color: #f8f8f8;
  border-color: #ddd;
  border-bottom: 0;
}
.myaccount-table table td, .myaccount-table .table td {
  padding: 10px;
  vertical-align: middle;
  border-color: #ddd;
}

.saved-message {
  background-color: #ddd;
  border-top: 3px solid #dcb14a;
  border-radius: 5px 5px 0 0;
  font-weight: 600;
  font-size: 15px;
  color: #666666;
  padding: 20px;
}

.faq-wrapper .panel.panel-default {
  border: 1px solid #ebebeb;
  margin-bottom: 20px;
}
.faq-wrapper .card, .faq-wrapper .card-header {
  border-radius: 0;
}
.faq-wrapper .card {
  border-bottom: none;
  margin-bottom: 20px;
  border: none;
  box-shadow: 0 18px 40px rgba(51, 51, 51, 0.1);
}
.faq-wrapper .card:last-child {
  margin-bottom: 0;
}
.faq-wrapper .card-header {
  padding: 0;
  border-bottom: 0;
  background-color: #ffffff;
}
.faq-wrapper .card-header h5 button {
  border: none;
  width: 100%;
  text-align: left;
  color: #000000;
  padding: 20px 30px;
  position: relative;
  overflow-wrap: break-word;
  white-space: normal;
  font-size: 18px;
  line-height: 1.2;
  font-weight: 500;
  border-radius: 5px;
  background: #ffffff;
  text-decoration: none;
}
@media only screen and (max-width: 479px) {
  .faq-wrapper .card-header h5 button {
    padding-left: 15px;
    padding-right: 20px;
  }
}
.faq-wrapper .card-header h5 button span {
  position: absolute;
  right: 35px;
  top: 50%;
  transform: translateY(-50%);
}
@media only screen and (max-width: 479px) {
  .faq-wrapper .card-header h5 button span {
    right: 15px;
  }
}
.faq-wrapper .card-header h5 button span i {
  font-size: 14px;
  visibility: hidden;
  opacity: 0;
  display: none;
}
.faq-wrapper .card-header h5 button:hover, .faq-wrapper .card-header h5 button:focus {
  text-decoration: none;
  color: #000000;
}
.faq-wrapper .card-header h5 button[aria-expanded=true] {
  color: #ffffff;
  background: #dcb14a;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.faq-wrapper .card-header h5 button[aria-expanded=true] span i:nth-child(2) {
  visibility: visible;
  opacity: 1;
  display: inline;
}
.faq-wrapper .card-header h5 button[aria-expanded=true]:before {
  height: 100%;
}
.faq-wrapper .card-header h5 button[aria-expanded=false] span i:nth-child(1) {
  visibility: visible;
  opacity: 1;
  display: inline;
}
.faq-wrapper .card-body {
  padding: 23px 30px 29px;
}

/*=======================================
=        14. preview Pages Css           =
=========================================*/
.hero-preview {
  width: 100%;
}
.hero-preview .single-hero-slider-one {
  height: 900px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-preview .single-hero-slider-one {
    height: 700px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-preview .single-hero-slider-one {
    height: 600px;
  }
}
@media only screen and (max-width: 767px) {
  .hero-preview .single-hero-slider-one {
    height: 500px;
  }
}
.hero-preview .bg-img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-attachment: fixed !important;
  padding-top: 250px;
  padding-bottom: 80px;
}
@media only screen and (max-width: 767px) {
  .hero-preview .bg-img {
    padding-top: 120px;
  }
}
.hero-preview .preview-hero-text {
  margin-top: 120px;
}
.hero-preview .preview-hero-text h1 {
  font-size: 100px;
  margin-bottom: 28px;
  font-weight: 600;
}
.hero-preview .preview-hero-text p {
  padding-bottom: 195px;
  font-size: 26px;
  line-height: 1.615;
}
.hero-preview .preview-hero-text a {
  color: #999;
  font-size: 48px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .hero-preview .preview-hero-text {
    margin-top: 100px;
  }
  .hero-preview .preview-hero-text h1 {
    font-size: 70px;
    margin-bottom: 18px;
  }
  .hero-preview .preview-hero-text p {
    padding-bottom: 95px;
    font-size: 18px;
    line-height: 1.615;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .hero-preview .preview-hero-text {
    margin-top: 60px;
  }
  .hero-preview .preview-hero-text h1 {
    font-size: 54px;
    margin-bottom: 18px;
  }
  .hero-preview .preview-hero-text p {
    padding-bottom: 95px;
    font-size: 18px;
    line-height: 1.615;
  }
}
@media only screen and (max-width: 767px) {
  .hero-preview .preview-hero-text h1 {
    font-size: 40px;
    margin-bottom: 18px;
  }
  .hero-preview .preview-hero-text p {
    padding-bottom: 95px;
    font-size: 18px;
    line-height: 1.615;
  }
}

.single-demo-page {
  transition: all 0.3s ease-in-out;
}
.single-demo-page img {
  border: 1px solid #cccccc;
  display: block;
}
.single-demo-page span {
  font-size: 16px;
  font-weight: 500;
  color: #000;
  text-align: left;
  margin-top: 32px;
  margin-left: 45px;
  text-transform: uppercase;
  letter-spacing: 1px;
  line-height: 1;
  display: inline-block;
  border-bottom: 1px solid transparent;
}
.single-demo-page:hover {
  transform: translateY(-10px);
}
.single-demo-page:hover span {
  border-color: #000;
}

.preview-footer-area.bg-img {
  background-size: cover !important;
  background-repeat: no-repeat !important;
  background-position: center !important;
  background-attachment: fixed !important;
}
.preview-footer-area .preview-footer-text {
  padding: 120px 0;
}
.preview-footer-area .preview-footer-text h2 {
  font-size: 48px;
  margin-bottom: 31px;
  line-height: 1.25;
}
.preview-footer-area .preview-footer-text p {
  font-size: 24px;
  line-height: 1.67;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .preview-footer-area .preview-footer-text {
    padding: 60px 0;
  }
  .preview-footer-area .preview-footer-text h2 {
    font-size: 28px;
  }
  .preview-footer-area .preview-footer-text p {
    font-size: 16px;
  }
}
@media only screen and (max-width: 767px) {
  .preview-footer-area .preview-footer-text {
    padding: 60px 0;
  }
  .preview-footer-area .preview-footer-text h2 {
    font-size: 22px;
  }
  .preview-footer-area .preview-footer-text p {
    font-size: 16px;
  }
}
.preview-footer-area .copyright {
  padding: 0 0 50px 0;
}

/*======================================
=        15. Button Css
=======================================*/
.btn {
  border-radius: 0;
  font-size: 15px;
  font-weight: 500;
  height: 50px;
  line-height: 38px;
  transition: all 0.4s cubic-bezier(0.645, 0.045, 0.355, 1);
  border: transparent;
}
.btn--sm {
  line-height: 38px;
  padding: 0 22px;
  height: 40px;
}
.btn--md {
  line-height: 38px;
  padding: 0 32px;
  height: 40px;
}
.btn--lg {
  line-height: 44px;
  padding: 0 42px;
  height: 46px;
}
.btn--black {
  background: #000000;
  color: #ffffff;
}
.btn--black:hover, .btn--black:focus {
  background: #222;
  color: #ffffff;
}
.btn--border_1 {
  border: 1px solid #222;
}
.btn--border_1:hover {
  border: 1px solid #222;
  background: #222;
  color: #ffffff;
}
.btn--full {
  width: 100%;
}
.btn--icon {
  border: 1px solid #222;
  text-align: center;
  height: 46px;
  line-height: 44px;
  padding: 0 22px;
}
.btn--icon:hover {
  border: 1px solid #222;
  background: #222;
  color: #ffffff;
}
.btn--text-icon {
  font-size: 18px;
  font-weight: 500;
}
.btn--text-icon i {
  margin-right: 10px;
}
.btn--border-bottom {
  font-size: 18px;
  text-decoration: underline;
  font-weight: 500;
  line-height: 1;
}
.btn--border-bottom:hover {
  text-decoration: underline;
}

.tf-btn {
  font-size: 18px;
  display: inline-block;
  height: 50px;
  line-height: 50px;
  padding: 0 28px;
  font-weight: 700 !important;
  text-align: center;
  background-color: #7baa0b;
  color: #fff;
}
.tf-btn:hover, .tf-btn:focus {
  color: #ffffff;
}
@media only screen and (max-width: 575px) {
  .tf-btn {
    padding: 0 10px;
    font-size: 12px;
  }
}

/*======================================
=        16. Featuted Product Css
=======================================*/
.images-text-bg img {
  max-width: 650px;
  max-height: 121px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .images-text-bg img {
    max-width: 250px;
    max-height: 101px;
  }
}
@media only screen and (max-width: 767px) {
  .images-text-bg img {
    max-width: 150px;
    max-height: 101px;
  }
}

.featuted-product-one .product-thumbnail a {
  display: block;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .featuted-product-one .product-thumbnail {
    margin-bottom: 30px;
  }
}
.featuted-product-one:hover .product-thumbnail a img {
  transform: scale(1.05);
}

.featured-pice {
  margin-top: 15px;
  font-size: 16px;
  color: #999;
}
.featured-pice span {
  color: #666;
  font-size: 24px;
  font-weight: 500;
}

/*================================================
=                 17. Product Css             =       
==================================================*/
.single-product-item {
  margin-top: 30px;
}
.single-product-item .products-images {
  position: relative;
}
.single-product-item .product-thumbnail {
  position: relative;
  display: block;
}
.single-product-item .product-thumbnail::after {
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.1);
  opacity: 0;
  visibility: hidden;
  transition: all 0.3s ease-in-out;
}
.single-product-item .product-thumbnail .ribbon {
  position: absolute;
  right: 15px;
  top: 15px;
  background: #000000;
  color: #ffffff;
  padding: 0 15px;
  z-index: 3;
}
.single-product-item .product-thumbnail .ribbon.out-of-stock {
  background: #000000;
}
.single-product-item .product-thumbnail .ribbon.onsale {
  background: #f14705;
}
.single-product-item .product-thumbnail > img {
  width: 100%;
}
.single-product-item .product-actions {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  align-items: center;
}
.single-product-item .product-actions a {
  width: 45px;
  height: 45px;
  line-height: 48px;
  background-color: #fff;
  text-align: center;
  border-radius: 50%;
  font-size: 20px;
  margin-left: 7px;
  margin-right: 7px;
  color: #000;
  transition: 0.5s;
  opacity: 0;
  transform: translateY(20px);
}
.single-product-item .product-actions a:nth-child(1) {
  transition-delay: 0s;
}
.single-product-item .product-actions a:nth-child(2) {
  transition-delay: 0.15s;
}
.single-product-item .product-actions a:nth-child(3) {
  transition-delay: 0.3s;
}
.single-product-item .product-actions a .tool-tip {
  position: absolute;
  background: #000;
  text-align: center;
  padding: 8px 12px;
  z-index: 3;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.6s, margin 0.3s;
  white-space: pre;
  font-size: 13px;
  line-height: 14px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  color: #fff;
  border-radius: 2px;
}
.single-product-item .product-actions a .tool-tip::after {
  border-color: rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) rgba(0, 0, 0, 0) #000;
  border-style: solid;
  border-width: 5px 0 5px 5px;
  content: "";
  height: 0;
  position: absolute;
  bottom: 10px;
  left: 50%;
  width: 0;
  bottom: -6px;
  transform: translateX(-50%) rotate(90deg);
}
.single-product-item .product-actions a:hover {
  color: #dcb14a;
}
.single-product-item .product-actions a:hover .tool-tip {
  visibility: visible;
  opacity: 1;
  top: -35px;
}
.single-product-item:hover .product-thumbnail::after {
  opacity: 1;
  visibility: visible;
}
.single-product-item:hover .product-actions a {
  transform: translateY(0);
  opacity: 1;
}
.single-product-item:hover .product-actions a:nth-child(1) {
  transition-delay: 0s;
}
.single-product-item:hover .product-actions a:nth-child(2) {
  transition-delay: 0.15s;
}
.single-product-item:hover .product-actions a:nth-child(3) {
  transition-delay: 0.3s;
}
.single-product-item .product-content {
  margin-top: 20px;
}
.single-product-item .product-content .prodect-title {
  font-size: 16px;
  margin-bottom: 5px;
}
.single-product-item .product-content .prodect-price span {
  color: #666;
  font-size: 18px;
}

.product-slider-active {
  position: relative;
  margin-left: -15px;
  margin-right: -15px;
}
.product-slider-active .single-product-item {
  padding-right: 15px;
  padding-left: 15px;
}
.product-slider-active .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  z-index: 3;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: all 0.3s ease-in-out;
}
.product-slider-active .slick-arrow.arrow-next {
  right: 0px;
  left: auto;
}
@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .product-slider-active .slick-arrow {
    left: 15px;
  }
  .product-slider-active .slick-arrow.arrow-next {
    right: 15px;
  }
}

/*================================================
=       18. Product Tab list Css             =       
==================================================*/
.product-tab-menu li {
  padding: 0 17px;
  margin-top: 10px;
  border-right: 1px solid #000;
  line-height: 12px;
  cursor: pointer;
  display: block;
}
.product-tab-menu li:last-child {
  border-right: none;
}
.product-tab-menu li:last-child {
  padding-right: 0;
}
.product-tab-menu li:first-child {
  padding-left: 0;
}
.product-tab-menu li .nav-link {
  color: #333;
  padding: 0;
}
.product-tab-menu li .nav-link.active {
  color: #999;
}

.tab-content .tab-pane {
  display: block;
  overflow: hidden;
  height: 0;
  visibility: hidden;
  max-width: 100%;
  opacity: 0;
}

.tab-content .tab-pane.active {
  height: auto;
  visibility: visible;
  opacity: 1;
  overflow: visible;
}

/*================================================
=     19. Single Product details Css             =       
==================================================*/
.product-modal-box .modal-dialog {
  margin: 5% auto 8%;
  max-width: 1170px;
}
.product-modal-box .modal-dialog .modal-content {
  border-radius: 0;
  padding: 0;
}
.product-modal-box .modal-body {
  padding: 0rem;
}
.product-modal-box .modal-dialog .modal-content .modal-header {
  border: none;
  padding: 0;
  right: 20px;
  top: 20px;
  position: absolute;
  z-index: 9999;
  color: #000000;
}
.product-modal-box .modal-dialog .modal-content .close {
  border: none;
  background-color: transparent;
  font-size: 30px;
}

.product-dec-small {
  margin: 5px;
}

.product-small-image {
  margin: 0 -10px;
  margin-top: 20px;
}

.product-dec-small {
  padding: 0 10px;
}

.product-small-image {
  position: relative;
}
.product-small-image .slick-arrow {
  font-size: 14px;
  line-height: 30px;
  width: 30px;
  height: 30px;
  cursor: pointer;
  position: absolute;
  background: #fff;
  top: calc(50% - 15px);
  text-align: center;
  left: 15px;
  right: auto;
  z-index: 3;
  color: #000;
  box-shadow: 0 0 5px 0px rgba(0, 0, 0, 0.15);
}
.product-small-image .slick-arrow.pro-dec-next {
  right: 0px;
  left: auto;
}

.product-details-content p {
  font-size: 14px;
}

.quickview-ratting-wrap {
  display: flex;
  font-size: 14px;
  align-items: center;
}
.quickview-ratting-wrap .quickview-ratting {
  display: flex;
  margin-right: 8px;
  color: #ddd;
}
.quickview-ratting-wrap .quickview-ratting .yellow {
  color: #F5A623;
}
.quickview-ratting-wrap .quickview-ratting i {
  margin: 0;
}

.quickview-content-wrap .price {
  font-weight: 400;
}
.quickview-content-wrap .stock p {
  color: #000000;
  font-weight: 600;
}
.quickview-content-wrap .stock.in-stock span {
  color: #3bc604;
}
.quickview-content-wrap .quickview-peragraph p {
  font-size: 14px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .quickview-content-wrap {
    padding: 40px 0;
  }
}
@media only screen and (max-width: 767px) {
  .quickview-content-wrap {
    padding: 40px;
  }
}

.quickview-action-wrap .quickview-cart-box {
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 575px) {
  .quickview-action-wrap .quickview-cart-box {
    flex-direction: column;
  }
}
.quickview-action-wrap .quickview-quality {
  border: 1px solid #ddd;
}
.quickview-action-wrap .quickview-button {
  display: flex;
  width: 100%;
}
.quickview-action-wrap .quickview-button .button {
  margin-left: 15px;
}
.quickview-action-wrap .quickview-wishlist a {
  width: 50px;
  height: 46px;
  line-height: 46px;
  display: inline-block;
  font-size: 20px;
  border: 1px solid #ddd;
  text-align: center;
}

.quickview-quality .cart-plus-minus {
  display: inline-block;
  height: 36px;
  overflow: hidden;
  padding: 0;
  position: relative;
  width: 120px;
  text-align: center;
}
.quickview-quality .cart-plus-minus .qtybutton {
  color: #333;
  cursor: pointer;
  float: inherit;
  font-size: 14px;
  line-height: 20px;
  margin: 0;
  position: absolute;
  text-align: center;
  transition: all 0.3s ease 0s;
  width: 24px;
}
.quickview-quality .cart-plus-minus .qtybutton.dec {
  height: 34px;
  top: 12px;
  left: 4px;
  font-weight: 600;
  font-size: 14px;
}
.quickview-quality .cart-plus-minus .qtybutton.inc {
  height: 34px;
  top: 12px;
  right: 4px;
  font-weight: 600;
  font-size: 14px;
}
.quickview-quality .cart-plus-minus input.cart-plus-minus-box {
  background: transparent none repeat scroll 0 0;
  border: medium none;
  color: #262626;
  font-size: 14px;
  height: 46px;
  line-height: 40px;
  margin: 0;
  padding: 0px;
  text-align: center;
  width: 100px;
  font-weight: 600;
}
.quickview-quality.quality-height-dec .cart-plus-minus {
  height: 45px;
}
.quickview-quality.quality-height-dec .cart-plus-minus .qtybutton.dec {
  height: 45px;
  top: 11px;
}
.quickview-quality.quality-height-dec .cart-plus-minus .qtybutton.inc {
  height: 45px;
  top: 12px;
}
.quickview-quality.quality-height-dec .cart-plus-minus input.cart-plus-minus-box {
  height: 45px;
  line-height: 45px;
}
.quickview-quality.quality-height-dec2 .cart-plus-minus {
  height: 45px;
  margin-left: -15px;
}
.quickview-quality.quality-height-dec2 .cart-plus-minus .qtybutton.dec {
  height: 45px;
  top: 11px;
  font-weight: 500;
  left: 22px;
}
.quickview-quality.quality-height-dec2 .cart-plus-minus .qtybutton.inc {
  height: 45px;
  top: 12px;
  font-weight: 500;
  right: 22px;
}
.quickview-quality.quality-height-dec2 .cart-plus-minus input.cart-plus-minus-box {
  height: 45px;
  line-height: 45px;
  width: 80px;
  font-weight: 400;
}

.product-support-text a {
  font-size: 14px;
  display: inline-block;
  margin-right: 10px;
}
.product-support-text a i {
  margin-right: 10px;
}

.quickview-meta span {
  display: block;
  font-size: 12px;
  margin-bottom: 10px;
}

.product_meta .item_meta {
  font-weight: 500;
  color: #666;
}
.product_meta .item_meta .label {
  color: #000000;
}
.product_meta .item_meta a:hover {
  color: #666;
  text-decoration: underline;
}

.product_socials {
  margin-top: 60px;
  display: flex;
  align-items: center;
}
@media only screen and (max-width: 767px), only screen and (min-width: 768px) and (max-width: 991px) {
  .product_socials {
    margin-top: 30px;
  }
}
.product_socials .helendo-social-share {
  margin-left: 20px;
}
.product_socials .helendo-social-share li {
  display: inline-block;
  margin-right: 10px;
}

.quickview-product-active .images {
  display: block !important;
}
.quickview-product-active .images img {
  width: 100%;
}
.quickview-product-active .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0px;
  z-index: 3;
  font-size: 20px;
  opacity: 0;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}
.quickview-product-active .slick-arrow.arrow-next {
  right: 0px;
  left: auto;
}
.quickview-product-active:hover .slick-arrow {
  left: 10px;
  opacity: 1;
}
.quickview-product-active:hover .slick-arrow.arrow-next {
  right: 10px;
  left: auto;
}

.image-swatches-nav li a {
  padding: 0;
  margin-right: 10px;
  border: 1px solid transparent;
}
.image-swatches-nav li a img {
  width: 80px;
}
.image-swatches-nav li a.active {
  border: 1px solid #dde;
}

.video-icons-popup a::after {
  font-family: "FontAwesome";
  content: "\f04b";
  width: 100px;
  height: 100px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.55);
  font-size: 30px;
  color: white;
  text-align: center;
  line-height: 100px;
  cursor: pointer;
  border-radius: 50%;
  z-index: 999999;
}

.video-style {
  position: relative;
}

.i-video {
  position: relative;
}
.i-video i {
  width: 41px;
  height: 41px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgba(0, 0, 0, 0.25);
  content: "\f04b";
  font-family: "FontAwesome";
  font-size: 15px;
  color: white;
  text-align: center;
  line-height: 41px;
  cursor: pointer;
  border-radius: 50%;
  font-style: none;
}

/*======================================
=         20. Countdown Css            =
=======================================*/
.countdown-deals {
  display: flex;
  justify-self: start;
}
.countdown-deals.counter-style--one .single-countdown {
  display: inline-block;
  margin-right: 80px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown-deals.counter-style--one .single-countdown {
    margin-right: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .countdown-deals.counter-style--one .single-countdown {
    margin-right: 30px;
  }
}
.countdown-deals.counter-style--one .single-countdown::after {
  position: absolute;
  content: ":";
  right: -40px;
  top: 25%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 40px;
  color: #000000;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown-deals.counter-style--one .single-countdown::after {
    font-size: 36px;
    right: -28px;
  }
}
@media only screen and (max-width: 767px) {
  .countdown-deals.counter-style--one .single-countdown::after {
    font-size: 26px;
    right: -20px;
  }
}
.countdown-deals.counter-style--one .single-countdown:last-child::after {
  display: none;
}
.countdown-deals.counter-style--one .single-countdown .single-countdown__time {
  display: block;
  font-family: "Prata", serif;
  font-size: 48px;
  font-weight: 400;
  line-height: 1;
  color: #000000;
  margin-bottom: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .countdown-deals.counter-style--one .single-countdown .single-countdown__time {
    font-size: 36px;
  }
}
@media only screen and (max-width: 767px) {
  .countdown-deals.counter-style--one .single-countdown .single-countdown__time {
    font-size: 28px;
  }
}
.countdown-deals.counter-style--one .single-countdown .single-countdown__text {
  margin-top: 10px;
  display: block;
  color: #000000;
  font-size: 18px;
  text-transform: uppercase;
  font-weight: 500;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .countdown-deals.counter-style--one .single-countdown .single-countdown__text {
    font-size: 16px;
    margin-top: 0px;
  }
}
.countdown-deals.counter-style--two .single-countdown {
  display: inline-block;
  margin-right: 30px;
  position: relative;
  color: #dcb14a;
}
.countdown-deals.counter-style--two .single-countdown::after {
  position: absolute;
  content: ":";
  right: -20px;
  top: 25%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  font-size: 20px;
  color: #dcb14a;
}
.countdown-deals.counter-style--two .single-countdown:last-child::after {
  display: none;
}
.countdown-deals.counter-style--two .single-countdown .single-countdown__time {
  display: block;
  font-size: 20px;
  font-weight: 400;
  line-height: 1;
  color: #dcb14a;
  margin-bottom: 0px;
}
.countdown-deals.counter-style--two .single-countdown .single-countdown__text {
  color: #dcb14a;
  font-size: 14px;
  font-weight: 400;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .countdown-deals.counter-style--two .single-countdown .single-countdown__text {
    font-size: 14px;
    margin-top: 0px;
  }
}
.countdown-deals.counter-style--three .single-countdown {
  display: inline-block;
  margin-right: 40px;
  position: relative;
  color: #dcb14a;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .countdown-deals.counter-style--three .single-countdown {
    margin-right: 20px;
  }
}
.countdown-deals.counter-style--three .single-countdown .single-countdown__time {
  display: block;
  font-size: 48px;
  font-weight: 400;
  line-height: 1;
  color: #dcb14a;
  margin-bottom: 0px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .countdown-deals.counter-style--three .single-countdown .single-countdown__time {
    font-size: 30px;
  }
}
.countdown-deals.counter-style--three .single-countdown .single-countdown__text {
  color: #000000;
  font-size: 16px;
  font-weight: 400;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .countdown-deals.counter-style--three .single-countdown .single-countdown__text {
    font-size: 16px;
    margin-top: 0px;
  }
}

/*======================================
=         21. Blog Css            =
=======================================*/
.single-blog-item {
  overflow: hidden;
}
.single-blog-item .blog-thumbnail-box {
  position: relative;
}
.single-blog-item .blog-thumbnail-box .thumbnail {
  display: block;
  width: 100%;
}
.single-blog-item .blog-thumbnail-box .btn-blog {
  position: absolute;
  right: 0;
  bottom: 0;
  padding: 5px 14px;
  border: none;
  background-color: #fff;
  color: #000;
  -webkit-transform: translateX(100%);
  -ms-transform: translateX(100%);
  transform: translateX(100%);
  opacity: 0;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
}
.single-blog-item .blog-thumbnail-box .btn-blog::after {
  font-family: ElegantIcons;
  content: "L";
  position: relative;
  left: 20px;
  -webkit-transition: all 0.8s;
  transition: all 0.8s;
}
.single-blog-item .blog-contents {
  margin-top: 25px;
}
.single-blog-item .blog-contents .blog-title {
  font-size: 20px;
  position: relative;
  padding-bottom: 15px;
}
.single-blog-item .blog-contents .blog-title a {
  color: #000000;
}
.single-blog-item .blog-contents .blog-title::before, .single-blog-item .blog-contents .blog-title::after {
  position: absolute;
  left: 0;
  bottom: -3px;
  content: "";
  height: 1.5px;
  width: 70px;
  background: rgba(180, 180, 180, 0.7);
  transition: all 0.8s;
}
.single-blog-item .blog-contents .blog-title::after {
  width: 0px;
}
.single-blog-item .blog-contents .blog-title-two {
  font-size: 22px;
  transition: all 0.4s ease-in-out;
}
.single-blog-item .blog-contents .blog-title-two a {
  transition: all 0.4s ease-in-out;
}
.single-blog-item .blog-contents .d_text {
  max-width: 800px;
}
.single-blog-item:hover .blog-thumbnail-box .btn-blog {
  transform: translateX(0);
  opacity: 1;
}
.single-blog-item:hover .blog-thumbnail-box .btn-blog::after {
  left: 0;
}
.single-blog-item:hover .blog-contents .blog-title::after {
  width: 70px;
  background: #dcb14a;
}
.single-blog-item:hover .blog-contents .blog-title-two a {
  text-decoration: underline;
}
.single-blog-item .meta-tag-box {
  display: flex;
  font-size: 14px;
  margin-top: 15px;
}
.single-blog-item .meta-tag-box .meta {
  position: relative;
}
.single-blog-item .meta-tag-box .meta::after {
  color: #999;
  padding: 0 8px;
  content: "/";
}
.single-blog-item .meta-tag-box .meta:last-child::after {
  display: none;
}
.single-blog-item .meta-tag-box .meta.cat {
  color: #999;
}
.single-blog-item .meta-tag-box .meta.cat a {
  color: #000000;
}
.single-blog-item .meta-tag-box .meta.cat a:hover {
  color: #dcb14a;
}

.blog-search input {
  width: 100%;
  border: none;
  height: 35px;
  border-bottom: 1px solid #ddd;
  background: transparent;
  padding: 0 40px 0 0;
}
.blog-search .submit-button {
  background: transparent;
  position: absolute;
  right: 15px;
  border: none;
}

.widget-blog-recent-post .widget-nav-list li {
  display: inline-block;
  color: #666;
  border-bottom: 1px solid #ddd;
  padding-bottom: 10px;
  margin-bottom: 10px;
  font-size: 14px;
}
.widget-blog-recent-post .widget-nav-list li a {
  color: #000000;
}
.widget-blog-recent-post .widget-nav-list li a .post-date {
  cursor: auto;
  color: #999;
}
.widget-blog-recent-post .widget-nav-list li:last-child {
  border-bottom: none;
  padding-bottom: 0px;
  margin-bottom: 0px;
}

.widget-blog-categories .widget-nav-list li {
  margin-bottom: 6px;
}
.widget-blog-categories .widget-nav-list li:last-child {
  margin-bottom: 0;
}
.widget-blog-categories .widget-nav-list li a {
  display: flex;
  justify-content: space-between;
}

.blog-tagcloud a {
  font-size: 16px;
  color: #767676;
  margin: 5px;
  padding: 8px 20px;
  display: inline-block;
  vertical-align: middle;
  background-color: #f3f4f7;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -webkit-transition: all 0.5s;
  transition: all 0.5s;
  font-weight: 400;
}
.blog-tagcloud a:hover {
  background: #000000;
  color: #ffffff;
}

blockquote {
  color: #000;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  padding-left: 35px;
  padding-top: 4px;
  margin: 35px;
  margin-right: 0;
  position: relative;
}
blockquote::before {
  content: "{";
  font-size: 28px;
  font-family: ElegantIcons;
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  display: block;
  position: absolute;
  left: 0;
  top: 0;
}

.blog-post-social-networks {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.blog-post-social-networks .item {
  display: inline-block;
  margin-right: 20px;
}
.blog-post-social-networks .item:last-child {
  margin-right: 0;
}
@media only screen and (max-width: 767px) {
  .blog-post-social-networks {
    justify-content: center;
    margin: 20px 0 0 0;
  }
}
.blog-post-social-networks .title {
  margin-right: 60px;
  font-size: 16px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .blog-post-social-networks .title {
    margin-right: 30px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .blog-post-social-networks .title {
    margin-right: 20px;
  }
}

.post-author-box {
  padding: 33px 35px;
  display: flex;
  background-color: #f4f5f7;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}
@media only screen and (max-width: 575px) {
  .post-author-box {
    padding: 33px 25px;
    flex-direction: column;
  }
}
@media only screen and (max-width: 767px) {
  .post-author-box {
    padding: 33px 25px;
  }
}
.post-author-box .post-author-info {
  margin-left: 55px;
}
@media only screen and (max-width: 767px) {
  .post-author-box .post-author-info {
    margin-left: 30px;
  }
}
@media only screen and (max-width: 575px) {
  .post-author-box .post-author-info {
    margin-left: 0;
    margin-top: 20px;
  }
}
.post-author-box .author-socials {
  padding: 0;
  margin: 20px 0 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
}
.post-author-box .author-socials li:after {
  content: "-";
  margin: 0 5px;
}
.post-author-box .author-socials li:last-child:after {
  content: "";
  margin: 0 5px;
}

.post-navigation {
  margin: 0 0 1.5em;
  overflow: hidden;
  margin: 0;
  padding: 40px 0;
  border-top: 1px solid #cacaca;
  border-bottom: 1px solid #cacaca;
  display: flex;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -moz-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.post-navigation .nav-links {
  display: -webkit-box;
  display: -webkit-flex;
  display: -moz-box;
  display: -ms-flexbox;
  display: flex;
  font-size: 16px;
  font-weight: 500;
  line-height: 1.2;
  position: relative;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -moz-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 50%;
}
.post-navigation .nav-links i {
  display: block;
  text-align: center;
  min-width: 30px;
  height: 70px;
  line-height: 70px;
  background-color: #f4f5f7;
  font-size: 16px;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.post-navigation .nav-links .nav-previous {
  padding-left: 40px;
  color: #000000;
}
@media only screen and (max-width: 575px) {
  .post-navigation .nav-links .nav-previous .d-text {
    display: none;
  }
}
.post-navigation .nav-links .nav-next {
  padding-right: 40px;
  color: #000000;
  text-align: right;
}
@media only screen and (max-width: 575px) {
  .post-navigation .nav-links .nav-next .d-text {
    display: none;
  }
}
.post-navigation .nav-links span {
  display: block;
  width: 100%;
  color: #999;
  font-size: 16px;
  font-weight: 400;
  margin-top: 4px;
}
.post-navigation .nav-links.post-next {
  -webkit-box-pack: end;
  -webkit-justify-content: flex-end;
  -moz-box-pack: end;
  -ms-flex-pack: end;
  justify-content: flex-end;
}

.comment-input-12 .comment-notes {
  width: 100%;
  padding: 10px;
  border: 1px solid #cfcfcf;
  height: 140px;
}

.comment-input-12 input {
  width: 100%;
  padding: 10px;
  border: 1px solid #cfcfcf;
  margin-top: 20px;
}
@media only screen and (max-width: 575px) {
  .comment-input-12 {
    width: 100%;
  }
}
@media only screen and (max-width: 767px) {
  .comment-input-12 {
    width: 100%;
  }
}

/*======================================
=         22. Newsletter Css            =
=======================================*/
.newsletter-wrap {
  position: relative;
}
.newsletter--one .input-box {
  border: none;
  background: #f4f5f7;
  width: 100%;
  height: 54px;
  padding: 10px 170px 10px 20px;
}
.newsletter--one .submit-btn {
  position: absolute;
  top: 0;
  right: 0px;
  font-size: 16px;
  opacity: 1;
  padding: 0 40px;
  border: none;
  height: 54px;
  background: #000000;
  color: #ffffff;
}
.newsletter--box {
  background: #f4f5f7;
  padding: 50px 70px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .newsletter--box {
    padding: 50px 15px;
  }
}
.newsletter--two {
  position: relative;
}
.newsletter--two .input-box {
  border: none;
  background: #fff;
  width: 100%;
  height: 54px;
  padding: 10px 70px 10px 20px;
}
.newsletter--two .submit-btn {
  position: absolute;
  top: 0;
  right: 15px;
  font-size: 16px;
  opacity: 1;
  padding: 0 30px;
  border: none;
  height: 54px;
  background: transparent;
  color: #000000;
}
.newsletter--two.max-420 {
  max-width: 420px;
}
.newsletter--three .single-input {
  position: relative;
}
.newsletter--three .input-box {
  border: none;
  background: #fff;
  width: 100%;
  height: 60px;
  padding: 10px 70px 10px 20px;
  color: #000000;
}
.newsletter--three .input-box::placeholder {
  color: #000000;
}
.newsletter--three .submit-btn {
  position: absolute;
  right: 15px;
  font-size: 16px;
  opacity: 1;
  padding: 0 30px;
  border: none;
  height: 60px;
  background: transparent;
  color: #000000;
  top: 50%;
  transform: translateY(-50%);
}
.newsletter--four .input-box {
  width: 100%;
  border: none;
  border-bottom: 1px solid #ddd;
  background: transparent;
  height: 40px;
  padding: 10px 50px 10px 0;
}
.newsletter--four .submit-button {
  background: transparent;
  position: absolute;
  right: 15px;
  border: none;
}

/*======================================
=         23. Banner Product Css            =
=======================================*/
.banner-product-image {
  position: relative;
  overflow: hidden;
}
.banner-product-image a {
  display: block;
}
.banner-product-image a img {
  width: 100%;
}
.banner-product-image a:hover {
  transform: scale(1.05);
}
.banner-product-image .product-banner-title {
  position: absolute;
  z-index: 99;
  top: 60px;
  left: 90px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .banner-product-image .product-banner-title {
    left: 40px;
    top: 40px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .banner-product-image .product-banner-title {
    left: 30px;
    top: 30px;
  }
}

.banner-images-one {
  overflow: hidden;
  position: relative;
}
.banner-images-one .thumbnail {
  display: block;
}
.banner-images-one .banner-title {
  position: absolute;
  z-index: 99;
  top: 60px;
  left: 50px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .banner-images-one .banner-title {
    top: 40px;
    left: 30px;
  }
}
.banner-images-one:hover .thumbnail img {
  transform: scale(1.04);
}
.banner-images-one:hover .banner-title .text-btn-normal {
  color: #999;
}
.banner-images-one .text-btn-normal:hover {
  color: #999;
}

.banner-area-box .banner-inner-box {
  margin-top: -135px;
  background: #fff;
  padding: 0 15px;
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .banner-area-box .banner-inner-box {
    margin-top: 35px;
  }
}
@media only screen and (max-width: 767px) {
  .banner-area-box .banner-inner-box {
    margin-top: 35px;
  }
}

.mt-130 {
  margin-top: 130px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .mt-130 {
    margin-top: 60px;
  }
}

.mt-180 {
  margin-top: 180px;
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .mt-180 {
    margin-top: 60px;
  }
}

.single-arival-product > a {
  display: block;
  overflow: hidden;
}
@media only screen and (max-width: 575px) {
  .single-arival-product > a img {
    width: 100%;
  }
}
.single-arival-product > a:hover img {
  transform: scale(1.02);
}
.single-arival-product .product-content {
  margin-top: 30px;
}
.single-arival-product .product-content .product-price {
  margin-top: 10px;
  color: #666;
  font-size: 18px;
  line-height: 30px;
}

.d-item {
  display: flex;
  flex-direction: column;
}

.single-product--03 > a {
  display: block;
  overflow: hidden;
}
.single-product--03 > a:hover img {
  transform: scale(1.02);
}
.single-product--03 .product-content {
  margin-top: -79px;
  margin-left: 80px;
  z-index: 33;
  position: relative;
}
.single-product--03 .product-content .product-price {
  margin-top: 10px;
  color: #666;
  font-size: 18px;
  line-height: 30px;
}
.single-product--03 .product-content.top--content {
  margin-bottom: -79px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .single-product--03 .product-content.top--content {
    margin-bottom: 30px;
  }
}
@media only screen and (max-width: 767px) {
  .single-product--03 .product-content {
    margin-left: 20px;
    margin-top: 30px;
  }
  .single-product--03 .product-content.top--content {
    margin-bottom: 0px;
  }
}

.single-shopping-support {
  display: flex;
  align-items: center;
  margin-bottom: 30px;
}
.single-shopping-support .icon {
  color: #dcb14a;
  font-size: 48px;
  margin-right: 26px;
}

/*======================================
=         24. Brand Css            =
=======================================*/
.single-brand-item {
  text-align: center;
}
.single-brand-item a {
  display: block;
}
.single-brand-item a img {
  opacity: 0.35;
  -webkit-transition: 0.5s;
  transition: 0.5s;
}
.single-brand-item a:hover img {
  opacity: 1;
}

.brand-slider-active .slick-arrow {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: -10px;
  z-index: 3;
  font-size: 24px;
  cursor: pointer;
  color: #666;
  transition: all 0.3s ease-in-out;
}
.brand-slider-active .slick-arrow.arrow-next {
  right: -10px;
  left: auto;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), only screen and (max-width: 767px) {
  .brand-slider-active .slick-arrow {
    left: 0;
  }
  .brand-slider-active .slick-arrow.arrow-next {
    right: 0px;
    left: auto;
  }
}

.category-section {
  padding-top: 60px;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .category-section {
    padding-top: 40px;
  }
}
@media only screen and (max-width: 767px) {
  .category-section {
    padding-top: 20px;
  }
}

.single-category-item {
  text-align: center;
  margin-top: 40px;
}
.single-category-item .content {
  margin-top: 25px;
}
.single-category-item .content .title {
  font-weight: 600;
  line-height: 1.125;
  margin: 0 0 10px;
}
.single-category-item .content .number {
  font-size: 16px;
  font-weight: 400;
  display: block;
  transition: all 0.5s ease 0s;
  color: #999;
}
.single-category-item:hover .category-item-thum img {
  transform: scale(1.1);
}

.category-item-thum {
  max-height: 240px;
  max-width: 240px;
  margin-left: auto;
  margin-right: auto;
}
.category-item-thum img {
  border-radius: 100%;
  width: 100%;
  transform: scale(1);
}